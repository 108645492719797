// eslint-disable-next-line import/no-anonymous-default-export
export default {
    LESS_THAN_50_M2: "Moins de 50 M2",
    LESS_THAN_150_M2: "Moins de 150 M2",
    MORE_THAN_150_M2: "Plus de 150 m2",
    NO_OFFICE_YET: "Je n'ai pas encore de local",
    NO_OFFICE_NEEDED: "Mon activité ne nécessite pas de local",
    ACCOUNTING_LEGAL_FINANCIAL_ACTIVITIES:
        "Activités comptables, juridiques et financières",
    COMPUTER_ACTIVITIES: "Activités informatiques",
    ARCHITECTURE_INTERIOR_DECORATION: "Architecture, décoration d'intérieur",
    ARTS_CRAFTS: "Artisanat",
    BUILDINGS_CONSTRUCTION_WORK: "Bâtiment travaux de construction",
    BEAUTY_AESTHETIC: "Beauté, esthétique",
    FOOD_TRADE: "Commerces alimentaires",
    NON_FOOD_TRADE: "Commerces non alimentaires",
    COMMUNICATION_EVENTS: "Communication, évènementiel",
    WEB_DESIGN_BLOGS_GRAPHICS: "Création de sites web, blogs, design / graphisme",
    ARTISTIC_CREATION:
        "Création artistique (création bijoux, tableaux d'œuvres de peinture, etc.)",
    E_COMMERCE: "E-commerce",
    HOTEL_ACCOMMODATION: "Hôtellerie, hébergement",
    INDUSTRY: "Industrie",
    HOBBIES: "Loisirs",
    DIGITAL_MARKETING: "Marketing digital",
    PNEUMATIC: "Pneumatique",
    CATERING_CAFE_SNACK: "Restauration, CAFE, SNACK",
    STARTUP_TECHNOLOGICAL: "Start-up technologique",
    HEALTH: "Santé",
    BUSINESS_SERVICE: "Services aux entreprises",
    SPORT: "Sport",
    TRANSPORT_LOGISTIC: "Transport et Logistique",
    TOURISM: "Tourisme",
    TEXTILE_FASHION_DESIGN: "Textile, fashion design",
    OTHER: "Autre",
    LESS_THAN_YEAR_ACTIVITY_NOT_STARTED:
        "Moins d'un an mais l'activité n'a pas encore démarré",
    TWO_YEARS_ACTIVITY_NOT_STARTED:
        "Jusqu'à 2 ans mais l'activité n'a pas encore démarré",
    LESS_THAN_YEAR_ACTIVITY_STARTED:
        "La société existe depuis moins d'un an et réalise déjà un chiffre d'affaires",
    MORE_THAN_YEAR_ACTIVITY_STARTED:
        "La société existe depuis plus d'un an et réalise déjà un chiffre d'affaires",
    COMPANY_NOT_CREATED: "Je n'ai pas encore créé de société",
    MALE: "MR",
    FEMALE: "Mrs",
    END_ACADEMIC_CAREER_STUDENT: "Etudiant en fin de parcours académique",
    EMPLOYEE: "Salarié",
    CEO: "Chef d'Entreprise",
    UNEMPLOYED: "Sans emploi",
    RETIRED: "Retraité(e)",
    AUTO_ENTREPRENEUR: "Auto-entrepreneur",
    NO_EMPLOYEE_TESTING_ACTIVITY_AND_VIABILITY:
        "Aucun, je souhaite d'abord tester l'activité et vérifier sa viabilité",
    NO_EMPLOYEE_WORKING_SOLO: "Aucun, je souhaite travailler seul(e)",
    NO_EMPLOYEE_AUTO_ENTREPRENEUR:
        "Aucun, puisqu'il s'agit d'une création en autoentrepreneur",
    LESS_THAN_3_EMPLOYEES: "Moins de 3 salariés",
    LESS_THAN_5_EMPLOYEES: "Moins de 5 salariés",
    MORE_THAN_5_EMPLOYEES: "Plus de 5 salariés",
    NO_CONTRIBUTION: "Je n'ai pas d'apport personnel",
    CONTRIBUTION_PERCENTAGE_10: "10%",
    CONTRIBUTION_PERCENTAGE_15: "15%",
    CONTRIBUTION_PERCENTAGE_20: "20%",
    UNTIL_CONTRIBUTION_PERCENTAGE_30: "Jusqu'à 30 %",
    MORE_THAN_CONTRIBUTION_PERCENTAGE_30: "Plus que 30%",
    LOCAL_IN_KIND_CONTRIBUTION: "Apport en nature local",
    LAND_IN_KIND_CONTRIBUTION: "Apport en nature terrain",
    MATERIAL_IN_KIND_CONTRIBUTION: "Apport en nature matériel",
    IN_POSSESSION_OF_FIRM_ORDER_BOOK:
        "Je dispose déjà d'un carnet de commande ferme",
    READY_TO_MARKET: "Prêt à être commercialisé",
    IN_DEVELOPMENT: "En cours de développement",
    AT_IDEA_STAGE: "Au stade de l'idée",
    UNTIL_300_000_DH: "Jusqu'à 300 000 DH",
    UNTIL_500_000_DH: "Jusqu'à 500 000 DH",
    UNTIL_700_000_DH: "Jusqu'à 700 000 DH",
    MORE_THAN_700_000_DH: "Plus de 700 000 DH",
    MATURING_CREATIVE_IDEA: "Mûrir son idée de création",
    MARKET_STUDY_BUSINESS_PLAN:
        "Réaliser son étude de marché et le business plan",
    OBTAIN_FUNDING: "Obtenir le financement",
    FIND_AID_AND_SUBSIDIES: "Trouver les aides et les subventions",
    CREATION_FORMALITIES: "Réaliser les formalités de création",
    EFFECTIVE_LAUNCH_OF_COMPANY_ACTIVITY:
        "Réaliser le lancement effectif de l'activité de l'entreprise",
    SUPPORT_AFTER_STARTING_ACTIVITY:
        "Un accompagnement après le démarrage de l'activité",
    JOIN_BUSINESS_CLUB: "Faire partie d'un club d'affaires",
    REQUEST_INITIALIZED: "Demande initiée",
    REQUEST_DENIED: "Demande d’accompagnement rejetée",
    BUSINESS_PLAN_IN_PROGRESS: "Business Plan en cours",
    BUSINESS_PLAN_FINALIZED: "Business plan finalisé",
    FUNDING_FILE_GRANTED: "Dossier de financement accordé",
    FUNDING_FILE_REJECTED: "Dossier de financement rejeté",
    START_OF_ACTIVITIES: "Démarrage des activités",
    SUSTAINED_ACTIVITIES: "Activités pérennisées",
    CLIENT_WITHDRAWAL: "Désistement client",
    PRIMARY_LEVEL: "Niveau primaire",
    MIDDLE_SCHOOL_LEVEL: "Brevet (Collège)",
    BAC_LEVEL: "Niveau BAC",
    BAC: "BAC",
    COLLEGE_LEVEL: "Etudes supérieures",
    LESS_THAN_50_000_DH: "Moins de 50 000 DH",
    FROM_50_000_TO_100_000_DH: "50 000 à 100 000 DH",
    UNTIL_200_000_DH: "Jusqu'à 200 000 DH",
    personnalnfo: "Informations Personnelles",

    fieldValidation: {
        necessaryField: "(*) Champ obligatoire",
        nameRequired: "(*) Nom obligatoire",
        mailRequired: "(*) E-mail obligatoire",
        phoneNumberInvalid: "(*) Numero de télèphone invalide",
        surnameRequired: "(*) Prenom obligatoire",
        birthRequired: "(*) Date de naissance obligatoire",
        addressRequired: "(*) Adresse de naissance obligatoire",
        cinRequired: "(*) Numero CIN obligatoire",
    },
    initStep: {
        Title: "Demande d'accompagnement",
        description:
            " Afin d'initer votre demande d'accompagnement par la Fondation nous vous invitons à renseigner les informations ci-dessous",
        name: "Nom",
        surname: "Prenom",
        mail: "E-mail",
        phone: "Telephone",
    },
    otpStep: {
        description:
            " Veuillez entrer le code de vérification à 6 chiffres que nous avons envoyé par e-mail",
        descriptionTwo:
            "(nous voulons nous assurer que c'est vous avant de contacter nos déménageurs)",
        otpCode: "CODE OTP",
    },
    innerStep: {
        hello: "Bonjour",
        description:
            "Afin d'initer votre demande d'accompagnement par lla Fondation nous vous invitons à renseigner les informations ci-dessous",
        name: "Nom",
        surname: "Prenom",
        mail: "E-mail",
        phone: "Telephone",
        birth: "Date de naissance",
        cin: "CIN",
        address: "Adresse",
        academicLevel: "Niveau académique",
        empty: "Vide",
        status: "Status",
        stepTwoTitle: "Informations à propos de l'entreprise",
        stepThreeTitle: "Informations à propos de l'entreprise",
        stepFourTitle: "Informations à propos de l'entreprise",
        yourProjectIs: "Votre Projet est de :",
        createCompany: "Créer une entreprise",
        relaunchCompany: "Reprendre une entreprise",
        developCompany: "Developper mon entreprise",
        headquarter: "Siege social de l'entreprise",
        companyaddress: "Adresse entreprise",
        district: "Quartier",
        city: "Ville",
        pickCity: "Choisir Ville",
        activity: "Secteur d'activité",
        companyAge: "Age de votre société",
        projectDescription: "Description du projet",
        activityNeedsFactory:
            "Votre activité nécessite-t-elle un local commercial ?",
        yes: "Oui",
        no: "Non",
        whatIsAreaOfPremises: "Quelle est la superficie de ce local ?",
        projectPrepared: "Vous préparez ce projet",
        alone: "Seul",
        withHelp: "Avec de l 'aide",
        ifWithHelp: "Si avec une aide",
        wishBeingHelpedByFCE: "Souhaitez-vous être accompagné par la FCE ?",
        whichHelpPhase: "Pour quelles phase souhaitez-vous être accompagné ?",
        numberOfEmployees: "Combien de personne va compter votre entreprise ? ",
        requestedFunding: "Financement sollicité",
        totalInvestmentAmount:
            " Montant total des investissements que nécessite votre entreprise",
        personalContributionPercentage:
            "Quelle part du montant des investissements nécessaires représente votre apport ?",
        requestedServices: "Prestations demandées",
        productDevelopmentStage:
            " Quelle est le stade de développement de votre produit",
        isBusinessPlanExists:
            "Est-ce-que vous disposez deja d'un business plan ?(Si Oui veuillez l'envoyer à l'adresse mail suivante :",
        hasValidatedConditions: "J'ai lu et j'accepte les conditions générales",
        generalConditions: "Conditions Générales d'Utilisation",
        success: " Votre Demande a été bien enregistrée",
    },
    next: "Suivant",
    validate: "Valider",
    back: "Précèdent",
};

//#region Imports
import React from "react";
import { connect } from "react-redux";

import { hideMessage } from "../../redux/messages/messages.actions";
import { LoaderBodyContainer } from "./Loader.styles";
import LoaderArt from "../Ui/LoaderArt/LoaderArt";
import BackDrop from "../Ui/BackDrop/BackDrop";
//#endregion
//**************************************** */

//#region Main Function Component

/**
 * @name Loader
 * @description Loader Function Component ( show / Hide)
 * @param {boolean} shown 
 * @param {boolean} hideMessage 
 * @param {boolean} show show or shown depends on context
 */
const Loader = (props) => {
  return (
    <>
      <BackDrop show={props.shown || props.show} clicked={props.hideMessage} />
      <LoaderBodyContainer show={props.shown || props.show}>
        <LoaderArt />
      </LoaderBodyContainer>
    </>
  );
};
//#endregion

//#region Redux Connection
const mapDispatchToProps = (dispatch) => ({
  hideMessage: () => {
    dispatch(hideMessage());
  },
});
const mapStateToProps = (state) => ({
  shown: state.Loader.shown,
});

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
//#endregion

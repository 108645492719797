import produce, { enableES5 } from "immer";
import AppContentActionTypes from "./appContent.types";
import { actionCreator } from "../../utils/generators";

enableES5();
const defaultState = {
  isLoading: false,
  error: null,
  news: [],
  categories: [],
  videos: [],
  pdfs: [],
  numberOfVideos: 0,
  numberOfPdfs: 0,
  numberOfNews: 0,
  showVideoModal: false,
  selectedCategories: [],
  selectedNews: null,
};

const appContentReducer = produce((draft, action) => {
  switch (action.type) {
    case actionCreator("req", AppContentActionTypes.GET_ALL_NEWS):
    case actionCreator("req", AppContentActionTypes.GET_ALL_VIDEO_TUTORIALS):
    case actionCreator("req", AppContentActionTypes.GET_ALL_CATEGORIES):
    case actionCreator("req", AppContentActionTypes.GET_ALL_PDF_TUTORIALS):
      draft.isLoading = true;
      draft.error = null;
      break;
    case actionCreator("req", AppContentActionTypes.TOGGLE_VIDEO_MODAL):
      draft.showVideoModal = action.payload;
      break;
    case actionCreator("req", AppContentActionTypes.SELECT_CATEGORY):
      draft.selectedCategories = action.payload;
      break;
    case actionCreator("req", AppContentActionTypes.SET_SELECTED_NEWS):
      draft.selectedNews = action.payload;
      break;
    case actionCreator("fail", AppContentActionTypes.GET_ALL_NEWS):
    case actionCreator("fail", AppContentActionTypes.GET_ALL_VIDEO_TUTORIALS):
    case actionCreator("fail", AppContentActionTypes.GET_ALL_CATEGORIES):
    case actionCreator("fail", AppContentActionTypes.GET_ALL_PDF_TUTORIALS):
      draft.error = "error found";
      draft.isLoading = false;
      break;
    case actionCreator("res", AppContentActionTypes.GET_ALL_NEWS):
      draft.error = null;
      draft.isLoading = false;
      draft.news = action.payload;
      draft.numberOfNews = action.payload.length;
      break;
    case actionCreator("res", AppContentActionTypes.GET_ALL_CATEGORIES):
      draft.error = null;
      draft.isLoading = false;
      draft.categories = action.payload;
      break;
    case actionCreator("res", AppContentActionTypes.GET_ALL_VIDEO_TUTORIALS):
      draft.error = null;
      draft.isLoading = false;
      draft.videos = action.payload;
      draft.numberOfVideos = action.payload.length;
      break;
    case actionCreator("res", AppContentActionTypes.GET_ALL_PDF_TUTORIALS):
      draft.error = null;
      draft.isLoading = false;
      draft.pdfs = action.payload;
      draft.numberOfPdfs = action.payload.length;
      break;
    default:
      break;
  }
}, defaultState);

export default appContentReducer;

import AxiosAPI from "../../rest/AxiosAPI";
import Config from "../../rest/Config";
import { ErrorHandler } from "./ErrorHandler";

export default class Request {
  _handleOnSuccess(response, resolver) {
    return resolver({ data: response.data, status: response.status });
  }

  _handleOnError(error, reject, ignoreTimeout, handleError) {
    return reject(ErrorHandler(error, ignoreTimeout, handleError));
  }

  getSelections = (ignoreTimeout, handleError) =>
    new Promise((resolver, reject) =>
      new AxiosAPI(true)
        .setUrl(Config.requests.ALL_SELECTIONS)
        .setContentType("application/x-www-form-urlencoded")
        .sendGet()
        .then((response) => this._handleOnSuccess(response, resolver))
        .catch((error) =>
          this._handleOnError(error, reject, ignoreTimeout, handleError)
        )
    );
  getCities = (ignoreTimeout, handleError) =>
    new Promise((resolver, reject) =>
      new AxiosAPI(true)
        .setUrl(Config.requests.ALL_CITIES)
        .setContentType("application/x-www-form-urlencoded")
        .sendGet()
        .then((response) => this._handleOnSuccess(response, resolver))
        .catch((error) =>
          this._handleOnError(error, reject, ignoreTimeout, handleError)
        )
    );
  initSupRequest = (initRequest) =>
    new Promise((resolver, reject) =>
      new AxiosAPI(false)
        .setUrl(Config.requests.INIT_REQUEST)
        .setData(initRequest)
        .sendPost()
        .then((response) => this._handleOnSuccess(response, resolver))
        .catch((error) => this._handleOnError(error, reject))
    );
  verifySupRequest = (verifyRequest) =>
    new Promise((resolver, reject) =>
      new AxiosAPI(true)
        .setUrl(Config.requests.VERIFY_INIT_REQUEST)
        .setData(verifyRequest)
        .sendPost()
        .then((response) => this._handleOnSuccess(response, resolver))
        .catch((error) => this._handleOnError(error, reject))
    );
  addPersonalInformation = (personalInfoRequest) =>
    new Promise((resolver, reject) =>
      new AxiosAPI(true)
        .setUrl(Config.requests.ADD_PERSONAL_INFORMATION)
        .setData(personalInfoRequest)
        .sendPost()
        .then((response) => this._handleOnSuccess(response, resolver))
        .catch((error) => this._handleOnError(error, reject))
    );
  addSupRequest = (supRequest) =>
    new Promise((resolver, reject) =>
      new AxiosAPI(true)
        .setUrl(Config.requests.ADD_REQUEST)
        .setData(supRequest)
        .sendPost()
        .then((response) => this._handleOnSuccess(response, resolver))
        .catch((error) => this._handleOnError(error, reject))
    );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    videotitle: "Accompagnement: Formations Video",
    businessModel: "Réaliser votre Business Model",
    elaborateBusinessPlan: "Élaborer votre Business Plan",
    financeProject: "Financer votre projet",
    launchCreationSteps: "Vous lancer dans les démarches de création",
    startActivity: "Démarrer votre activité",
    entrepreneurPathSummary: "Résumé du parcours de l’entrepreneur pour",
    winAndAmorceProject: "réussir et amorcer son projet d’entreprise",
    accompaniment: "Programme d'accompagnement",
    accompanimentDescription:
        "Le processus d’accompagnement entrepreneurial de la Fondation Création d’Entreprises démarre dès l’idéation et se poursuit jusqu’à l’aboutissement du projet. \n" +
        "Lors de chaque phase de la vie de votre projet, vous bénéficierez de l'offre gratuite de la FCE qui englobe :\n",
    accompanimentDescription1:
        "de l'information et du renseignement sur l'écosystème entrepreneurial ;",
    accompanimentDescription2:
        "une assistance de proximité par nos conseillers régionaux ;",
    accompanimentDescription3:
        "de la formation entrepreneuriale et managériale en pré et en post-création ;",
    accompanimentDescription4:
        "des séances d'échange avec des chefs d'entreprises aguerris ;",
    accompanimentDescription5: "un programme de networking. ",
    next: "Suivant",
    previous: "Précedent",
    knowMore: "En savoir plus",
    knowMoreDetail:
        "Lien vers le portail national d’appui à l’Entrepreneuriat : ",
    knowMoreUrl: "https://www.almoukawala.gov.ma/fr",
    moukawilPortal: "Lien vers le portail Al Moukawil Chaâbi :",
    mokawilPortalUrl: "https://www.almoukawilchaabi.ma/MoukawilChaabi/fr/",
    almokawilUrl: "https://www.almoukawala.gov.ma/fr",
    almokawilDetails:
        "Pour toute assistance, prière de contacter votre conseiller régional FCE au niveau de l’antenne régionale relevant de la circonscription de votre projet",
    seeOurMapping: "(consulter notre MAPPING)",
    urRegionalConsultant:
        ". Votre conseiller régional, se fera un plaisir de vous contacter pour les étapes à venir",
    stepSchema: {
        assistanceForm: "Formulaire d’assistance",
        businessModel: "Business Model​​",
        businessPlan: "Business Plan",
        fundingFile: "Dossier de financement",
    },
    financialFile: {
        description1:
            "Un plan de financement bien structuré permet à votre banquier de vérifier la pertinence de vos choix de financement par rapport à votre programme d’investissement. Votre programme de financement va déterminer votre niveau d’engagement dans le projet (ce que l’on appelle communément « autofinancement »). Sachez que plus vos apports en espèces ou en nature (local, etc.) sont conséquents, plus vos chances d’obtenir un accord de financement seront importantes, sous réserve que votre projet génère une rentabilité suffisante.",
        description2:
            " Il en est de même pour la diversification de vos sources de financement. Votre banquier appréciera cette diversification du risque si vous faites appel, dans la mesure du possible, à des aides et subventions, à des prêts d’honneur, ou encore à des prêts participatifs gérés par des fonds d’amorçage et des structures de capital-risque.",
        description3:
            "Dès lors, vous êtes vivement invités à vous renseigner sur la panoplie d’offres d’aides et de financements disponibles. Ci-après, une présentation synoptique du panorama des types de financement existants.",
        autoFinance: "Autofinancement",
        autoFinanceDetail1:
            " Durant la  phase d’amorçage de votre projet, vous allez devoir mettre la main à la poche pour financer une partie de votre programme d’investissement qui inclura, à titre d’exemple, les frais d’établissement, l’agencement de votre local, l’acquisition de matériel et logiciels, la constitution d’un stock initial et la disponibilité d’un fonds de démarrage de vos activités. Généralement, les banques exigent l’autofinancement d’au moins 10% du programme d’investissement. Cet autofinancement, qui peut prendre la forme d’un apport en espèce ou en nature, constitue un gage d’engagement de votre part vis-à-vis de votre partenaire financier.",
        autoFinanceDetail2:
            " Pour constituer votre capital de départ, vous pouvez également faire appel à vos proches et amis.",
        financialAids: " Fonds Publics, Privés",
        financialAidsDetail1:
            " Capital-risque et amorçage : Investissement dans des entreprises en démarrage",
        financialAidsDetail2:
            "Capital développement : Investissement dans des entreprises en développement, avec généralement au moins 3 à 5 ans",
        financialAidsDetail3:
            " Maroc PME, à travers son offre intégrée d’accompagnement de bout en bout des projets de startups, de l’idée à l’industrialisation en passant par les différentes phases d’incubation.",
        financialAidsDetail4:
            "Les mesures de soutien sectorielles (Plan d'Accélération Industrielle, Plan Maroc Vert, Plan Rawaj).",
        moreDetails:
            "Pour plus d'informations, consultez les sites des organismes partenaires",
        honorMorgageNoIntrests: "Prêts d'honneur sans intérêts ",
        honorMorgageNoIntrestsDetail1: "CCG, à travers ses offres :",
        honorMorgageNoIntrestsDetail2:
            " Innov Start, dédiée aux porteurs de projets ayant une idée innovante. Start TPE, dans le cadre du financement Intelaka.",
        honorMorgageNoIntrestsDetail3:
            " Start TPE, dans le cadre du financement Intelaka",
        capitalAmorcingAndRisque: "Capital amorçage et capital-risque",
        capitalAmorcingAndRisqueDetail1:
            " La CCG, à travers ses solutions d'amorçage et de capital-risque",
        capitalAmorcingAndRisqueDetail2:
            " L’Association Marocaine des Investisseurs en Capital (AMIC)",
        capitalAmorcingAndRisqueDetail3:
            " SEAF Morroco Capital Partners (Digital, agri-busines, éducation, santé)",
        capitalAmorcingAndRisqueDetail4:
            " Maroc Numéric Fund II (technologies de l'information et de la communication, greentechs et Biotech)",
        capitalAmorcingAndRisqueDetail5:
            " Green Innov Invest (Technologies de l'information et de la communication, greentechs et Biotech)",
        coFinance: "Cofinancement CCG/BANQUES",
        coFinanceDetail: "La CCG, à travers ses offres MDM INVEST",
        bankFinances: "Financements des établissements de crédit",
        bankFinancesDetail0: "Financement de l’investissement :",
        bankFinancesDetail1: "Crédit bancaire INTELAK dans le cadre du programme PIAFE (Programme intégré d’appui et de financement des entreprises)",
        bankFinancesDetail2: "Crédit à moyen ou à long terme",
        bankFinancesDetail4: "Financement alternatif",
        bankFinancesDetail5: "Offre de location longue durée et leasing (essentiellement pour les TPE en activité).",
        bankFinancesDetail6: "Financement du cycle d’exploitation pour les TPE en activité :",
        bankFinancesDetail7: "Crédits de trésorerie, tels que la facilité de caisse, l’escompte commercial, le factoring et le crédit de campagne.",
        bankFinancesDetail8: "Financement sous forme d’avance, tels que le financement des marchés publics, avance sur factures, avance sur marchandises et avances sur crédit de TVA.",
        bankFinancesDetail9: "Cautions marché destinées aux entreprises participantes aux appels d’offres.",
        bankFinancesDetail10: "Financement des opérations à l’international (crédit documentaire, les avals …)",
        videotitle: "Dossier de Financement: Formations Video",
        knowMore: "En savoir plus",
        knowMoreDetail:
            "Lien vers le portail national d’appui à l’Entrepreneuriat : ",
        knowMoreUrl: "https://www.almoukawala.gov.ma/fr",
        moukawilPortal: "Lien vers le portail Al Moukawil Chaâbi :",
        mokawilPortalUrl: "https://www.almoukawilchaabi.ma/MoukawilChaabi/fr/",
        almokawilUrl: "https://www.almoukawala.gov.ma/fr",
        almokawilDetails:
            "Pour toute assistance, prière de contacter votre conseiller régional FCE au niveau de l’antenne régionale relevant de la circonscription de votre projet",
        seeOurMapping: "(consulter notre MAPPING)",
        urRegionalConsultant:
            ". Votre conseiller régional, se fera un plaisir de vous contacter pour les étapes à venir",
        seeOurCapsules: "Consultez nos capsules",
        videosToAdd: " vidéos « Panorama des financements »",
        forMoreDetails: "Pour plus de détails",
    },
    creation: {
        moreDetails: "Pour plus d'information, veuillez consulter le lien ",
        description1:
            "Avant de vous lancer dans les démarches de création, vous devrez choisir la structure juridique adaptée à votre projet. Votre choix dépendra de plusieurs critères.",
        activityNature: "La nature de votre activité",
        activityNatureDetail:
            "Certaines activités ne peuvent être exercées que sous une forme juridique bien définie.  :",
        activityNatureDetail1:
            " Les professions libérales ne peuvent être exercées que sous la forme d’entreprises individuelles.",
        activityNatureDetail2:
            " Certaines activités sont exclues du bénéfice du statut d'auto-entrepreneur (Décret n° 2-15-263 amendé par le décret n°2-15-942)",
        projectDimention: "La dimension de votre affaire",
        projectDimentionDetails:
            "Si vous envisagez d’opter pour le statut d’auto-entrepreneur, votre chiffre d’affaires annuel ne doit pas dépasser :",
        projectDimentionDetail1:
            " pour les activités commerciales, industrielles et artisanales ;",
        projectDimentionDetail2: " pour les activités de prestations de services.",
        projectDimentionDetail3:
            "Si votre business plan dégage un volume d’affaires plus conséquent, vous devrez opter pour une entreprise commerciale (entreprise individuelle, SARL, etc.).",
        associationWill: "Votre volonté de vous associer",
        associationWillDetail:
            "Vous voulez créer une entreprise et vous vous demandez si vous devez vous associer. Pesez le pour (mutualisation des efforts, complémentarité des compétences, partage des bénéfices mais aussi des pertes, etc.) et le contre (risques de mésentente, etc.). Si, à l’issue de cette exercice, vous n’avez pas la volonté de vous associer, il vaudra mieux vous orienter, par exemple, vers une entreprise individuelle ou encore une SARL à associé unique.",
        urResponsability: "Votre responsabilité",
        urResponsabilityDetail:
            "S’agissant de l’entreprise individuelle et des sociétés de personnes où les associés sont choisis intuitu personae, c’est-à-dire en fonction de la personne (société en nom collectif, société en commandite simple…), votre responsabilité et celle de vos associés sur le passif de l’affaire est indéfinie et le plus souvent solidaire ou conjointe. S’agissant des sociétés de capitaux (dominées par l’intuitu pecuniae), les capitaux apportés par les associés comptent plus que leur personne (société anonyme, société à responsabilité limitée, société en commandite par actions…).Votre responsabilité ainsi que celle des associés est alors limitée aux apports.",
        governanceMode:
            "Le mode de gouvernance et de fonctionnement que vous aurez choisi",
        governanceModeDetail:
            " Chaque structure juridique a ses propres règles de fonctionnement et de gouvernance.  Dans le cas de l’entreprise individuelle, vous êtes maître à bord ; en revanche, dans les sociétés commerciales, vous agissez au nom et pour le compte de la société créée. De ce fait, vous devez observer les règles de bonne gouvernance, telles que la clarification des pouvoirs des dirigeants et des actionnaires, la tenue des conseils d’administration et des assemblées générales ou encore la nomination des commissaires aux comptes.",
        billsImposition: "Le choix de votre imposition fiscale",
        billsImpositionDetail:
            "Selon votre choix juridique de création, vous serez soumis soit à l’impôt sur le revenu (IR) selon le barème fiscal, comme c’est le cas des entreprises individuelles, soit à l’impôt sur les sociétés (IS), à l’instar des SARL.",
        creationSteps: "LES DÉMARCHES DE CRÉATION",
        negatifCertificatIntro:
            "Pour mener à bien les démarches administratives et juridiques, vous êtes priés de vous adresser à votre guichet d'aide à la création au niveau du CRI relevant de la circonscription régionale de votre projet.",
        negatifCertificatIntroTwo:
            "À titre indicatif, vous trouverez ci-dessous les étapes et les formalités de création de votre entreprise :",
        negatifCertificat:
            "Production de certificat négatif pour la dénomination de l’entreprise",
        negatifCertificatDetail1: "Délivré par l'OMPIC",
        negatifCertificatDetail2:
            " Documents demandés : imprimé à retirer auprès de votre CRI, copie de votre CIN.",
        negatifCertificatDetail3: "Frais à titre indicatif : 170 DH.",
        statusEstablishing: "Établissement des statuts",
        statusEstablishingDetail1:
            "S'adresser aux cabinets juridiques, notaires, avocats ou encore experts comptables.",
        statusEstablishingDetail2:
            "Frais à engager : honoraires et droits d'enregistrement à hauteur d'environ 1,5% du capital assortis d’un minimum de 1000 DH.",
        subscriptionBillEstablishing: "Établissement des bulletins de souscription",
        subscriptionBillEstablishingDetail1:
            "Applicable uniquement aux sociétés commerciales (SNC, SARL, SCA, SAS, etc.).",
        subscriptionBillEstablishingDetail2:
            " S'adresser aux cabinets juridiques, notaires, avocats ou encore experts-comptables.",
        subscriptionBillEstablishingDetail3: "Frais : honoraires à négocier.",
        establishsubscriptionGaps:
            "Établissement des déclarations de souscription/versement",
        establishsubscriptionGapsDetail1: "•Applicable aux sociétés commerciales.",
        establishsubscriptionGapsDetail2:
            "À déposer au greffe du tribunal du lieu du siège social.",
        establishsubscriptionGapsDetail3:
            "S'adresser aux cabinets juridiques, notaires, avocats ou encore experts-comptables.",
        creationOfDepotActs:
            " Dépôt des actes de création et formalités d’enregistrement",
        creationOfDepotActsDetail1: "Applicable aux sociétés commerciales",
        creationOfDepotActsDetail2:
            " À déposer auprès de votre Direction Régionale des Impôts, 30 jours après l'établissement de l'acte de création.",
        creationOfDepotActsDetail3:
            "Les frais de dépôt varient en fonction de la forme juridique.",
        patenteSubscription: "Inscription à la patente et à l’identifiant fiscal",
        patenteSubscriptionDetail1: "Applicable à toutes les formes juridiques.",
        patenteSubscriptionDetail2:
            "Documents à produire : agrément ou diplôme si activité réglementée, contrat de bail ou acte d'acquisition ou attestation de domiciliation.",
        patenteSubscriptionDetail3:
            "S'adresser à la Direction Régionale des Impôts.",
        patenteSubscriptionDetail4: "Frais: 0 DH.",
        commercialRegistreImmatriculation:
            "Immatriculation au Registre de commerce",
        commercialRegistreImmatriculationDetail1:
            "Applicable à toutes les sociétés commerciales, à l'exception de la société en participation",
        commercialRegistreImmatriculationDetail2:
            "À déposer au Tribunal de commerce.",
        commercialRegistreImmatriculationDetail3:
            "Frais à titre indicatif : entre 150 et 350 DH.",
        cnssAffiliation: "Affiliation à la CNSS",
        cnssAffiliationDetail1: "Applicable à toutes les sociétés commerciales.",
        cnssAffiliationDetail2: "S’adresser à la CNSS.",
        cnssAffiliationDetail3: "Frais : 0 DH.",
        officialPub: "Publications officielles",
        officialPubDetail1: "Applicable à toutes les sociétés commerciales",
        officialPubDetail2: "Frais d'annonce",
        almokawilDetails:
            "Pour toute assistance, prière de contacter votre conseiller FCE au niveau de l’antenne régionale relevant de la circonscription de votre projet (consulter la liste de nos antennes régionales). ",
        urRegionalConsultant:
            "Votre conseiller se fera un plaisir de répondre à toutes vos questions. ",
        forMoreDetails: "Pour plus de détails",
        seeOurCapsules: "Consultez notre capsule",
        videosToAdd: "vidéos « Formalités administratives »",
    },
    businessPlan: {
        description1:
            "Vous vous considérez apte à mener l’expérience entrepreneuriale et vous avez réussi votre exercice de « Business Model ». Vous devez à présent formaliser les axes de votre projet dans un document appelé le « Business Plan ».",
        description3:
            "Le Business Plan vous permettra de vérifier la viabilité et la pertinence de votre projet en termes de stratégies commerciale, opérationnelle et financière sur un horizon de 5 à 7 ans. Il constituera une pièce maîtresse de votre dossier de financement et sera votre allié pour convaincre votre partenaire financier.",
        description2:
            "  Si vous voulez vous approprier les tenants et les aboutissants du business plan, consulter nos capsules vidéo portant sur le «",
        businessPlan: "Business Plan",
        downloadBusinessPlan:
            "Téléchargez et renseignez les canevas « Business Plan - Etude Commerciale » et « Business Plan - Etude Technique et financière » en cliquant sur les boutons ci-après.",
        commercialStudy: "Business Plan - Etude Commerciale",
        technicalStudy: " Élaborer votre Business Plan",
        technicalStudyDetails:
            "Une fois votre business plan dûment renseigné, merci de l’adresser par email à votre conseiller FCE au niveau de l’antenne régionale relevant de la circonscription de votre projet (consultez la liste de nos antennes régionales). Votre conseiller régional, se fera un plaisir de vous contacter pour vous guider dans la réalisation des étapes suivantes. ",
        bpr: "Fondation Création d'Entreprises",
        city: "Villes (BRF)",
        email: "Email",
        phone: "Téléphone",
        seeOurCapsules: "Consultez nos capsules",
        videosToAdd:
            "vidéos « Business Plan », « Plan d'action opérationnel » et « Les erreurs à éviter »",
    },
    businessModelTab: {
        description1:
            "Vous souhaitez évaluer la faisabilité de votre projet et confronter votre idée à votre environnement économique et réglementaire, vous devez réaliser un « Business Model ».",
        toSucceedThisExercise: "Pour réussir cet exercice :",
        seeOurCapsules: "Consultez nos capsules",
        videosToAdd:
            " vidéos « Murir son idée », « Soft-skills de l'entrepreneur », « Etude de marché » et « Business Model »",
        downloadCanvas:
            " Téléchargez notre canevas de Business Model en cliquant sur le bouton ci-après et répondez aux questions posées.",
        downloadThisCanvas: " Remplir votre Business Model en ligne ",
        dontHesitateToContactUs:
            "N’hésitez pas à consulter nos conseillers régionaux qui se feront un plaisir de vous accueillir et de vous assister pour élaborer la bonne recette pour gagner de l’argent.",
        onceBusinessModelDone:
            " Une fois votre Business Model renseigné, merci de l’adresser par email à votre conseiller FCE au niveau de l’antenne régionale relevant de la circonscription de votre projet (consultez la liste de nos antennes régionales). Votre conseiller régional, se fera un plaisir de vous contacter pour vous guider dans la réalisation des étapes suivantes. ",
        bpr: "Fondation Création d'Entreprises",
        city: "Villes (BRF)",
        email: "Email",
        phone: "Téléphone",
        clickHereForMoreVideos: "Cliquez ici pour accéder à l'ensemble des videos",
    },
    toolBox: {
        inProgress: "En cours de Developpement",
        checkVideos: "Consultez la capsule vidéo \" Créer mon entreprise \""
    },
};

import Components from "../../components/locales/en";
import Errors from "../../services/apis/Errors/locales/fr";
import Form from "../../features/Form/locales/fr";
import Home from "../../features/Home/locales/en";

export default {
  Components,
  Errors,
  Form,
  Home,
};

import produce, { enableES5 } from "immer";
import RequestActionType from "./request.types";
import { actionCreator } from "../../utils/generators";

enableES5();
const defaultState = {
  isLoading: false,
  error: null,
  areaOfPremisesList: [],
  companyActivityList: [],
  companyAgeList: [],
  genderList: [],
  jobStatusList: [],
  numberOfEmployeeList: [],
  personalContributionPercentageList: [],
  productDevelopmentStageList: [],
  requestedFundingList: [],
  requestedServicesList: [],
  requestStatusList: [],
  schoolLevelList: [],
  totalInvestmentAmountList: [],
  selectedProjectMainObjective: null,
  headquarter: null,
  activity: null,
  address: null,
  district: null,
  cityCode: null,
  selectedCompanyAge: null,
  description: null,
  isNeedingOffice: false,
  selectedAreaOfPremises: null,
  projectSolo: false,
  helpers: null,
  isFCEHelpNeeded: false,
  helpPhase: null,
  cities: [],
  step: 0,
  innerStep: 0,
  company: {},
};

const requestReducer = produce((draft, action) => {
  switch (action.type) {
    case actionCreator("req", RequestActionType.GET_ALL_SELECTIONS):
    case actionCreator("req", RequestActionType.GET_ALL_CITIES):
    case actionCreator("req", RequestActionType.VERIFY_INIT_REQUEST):
    case actionCreator("req", RequestActionType.ADD_REQUEST):
      draft.isLoading = true;
      draft.error = null;
      break;
    case actionCreator("req", RequestActionType.ADD_PERSONAL_INFORMATION):
      draft.isLoading = true;
      draft.error = null;
      draft.client.birthday = action.payload.birthday;
      draft.client = { ...draft.client, ...action.payload };
      break;
    case actionCreator("req", RequestActionType.INIT_REQUEST):
      draft.isLoading = true;
      draft.error = null;
      draft.client = action.payload;
      break;
    case actionCreator("req", RequestActionType.SET_STEP):
      draft.step = action.payload;
      break;
    case actionCreator("req", RequestActionType.SET_STEP_TWO_DATA):
      draft.selectedProjectMainObjective =
        action.payload.selectedProjectMainObjective;
      draft.company = action.payload;
      draft.innerStep = 2;
      break;
    case actionCreator("req", RequestActionType.SET_STEP_THREE_DATA):
      draft.isNeedingOffice = action.payload.isNeedingOffice;
      draft.selectedAreaOfPremises = action.payload.selectedAreaOfPremises;
      draft.projectSolo = action.payload.projectSolo;
      draft.helpers = action.payload.helpers;
      draft.isFCEHelpNeeded = action.payload.isFCEHelpNeeded;
      draft.helpPhase = action.payload.helpPhase;
      draft.innerStep = 3;
      break;
    case actionCreator("req", RequestActionType.SET_INER_STEP):
      draft.innerStep = action.payload;
      break;
    case actionCreator("fail", RequestActionType.GET_ALL_SELECTIONS):
    case actionCreator("fail", RequestActionType.GET_ALL_CITIES):
    case actionCreator("fail", RequestActionType.INIT_REQUEST):
    case actionCreator("fail", RequestActionType.VERIFY_INIT_REQUEST):
    case actionCreator("fail", RequestActionType.ADD_PERSONAL_INFORMATION):
    case actionCreator("fail", RequestActionType.ADD_REQUEST):
      draft.error = "error found";
      draft.isLoading = false;
      break;
    case actionCreator("res", RequestActionType.GET_ALL_SELECTIONS):
      draft.error = null;
      draft.isLoading = false;
      draft.schoolLevelList = action.payload.schoolLevelList.filter(
        (schoolLevel) => schoolLevel.name !== "NONE"
      );
      draft.jobStatusList = action.payload.jobStatusList.filter(
        (jobStatus) => jobStatus.name !== "NONE"
      );
      draft.companyActivityList = action.payload.companyActivityList.filter(
        (companyActivity) => companyActivity.name !== "NONE"
      );
      draft.companyAgeList = action.payload.companyAgeList.filter(
        (companyAge) => companyAge.name !== "NONE"
      );
      draft.areaOfPremisesList = action.payload.areaOfPremisesList.filter(
        (area) => area.name !== "NONE"
      );
      draft.numberOfEmployeeList = action.payload.numberOfEmployeeList.filter(
        (number) => number.name !== "NONE"
      );
      draft.requestedFundingList = action.payload.requestedFundingList.filter(
        (funding) => funding.name !== "NONE"
      );
      draft.totalInvestmentAmountList =
        action.payload.totalInvestmentAmountList.filter(
          (total) => total.name !== "NONE"
        );
      draft.personalContributionPercentageList =
        action.payload.personalContributionPercentageList.filter(
          (percentage) => percentage.name !== "NONE"
        );
      draft.requestedServicesList = action.payload.requestedServicesList.filter(
        (service) => service.name !== "NONE"
      );
      draft.productDevelopmentStageList =
        action.payload.productDevelopmentStageList.filter(
          (stage) => stage.name !== "NONE"
        );
      break;
    case actionCreator("res", RequestActionType.GET_ALL_CITIES):
      draft.error = null;
      draft.isLoading = false;
      draft.cities = action.payload.cityList.filter(
        (city) => city.cityName !== "NONE"
      );
      break;
    case actionCreator("res", RequestActionType.INIT_REQUEST):
      draft.message = action.payload.message;
      draft.otpId = action.payload.otpId;
      draft.step = 1;
      break;
    case actionCreator("res", RequestActionType.ADD_PERSONAL_INFORMATION):
      draft.message = action.payload.message;
      draft.clientCode = action.payload.clientCode;
      draft.innerStep = 1;
      break;
    case actionCreator("res", RequestActionType.VERIFY_INIT_REQUEST):
      draft.message = action.payload.message;
      draft.step = 2;
      break;
    case actionCreator("res", RequestActionType.ADD_REQUEST):
      draft.innerStep = 0;
      draft.message = action.payload.message;
      break;
    case "REDIRECT_TO_FORM":
      draft.step = 2;
      draft.innerStep = 0;
      break;
    default:
      break;
  }
}, defaultState);

export default requestReducer;

import AxiosAPI from "../../rest/AxiosAPI";
import Config from "../../rest/Config";
import { ErrorHandler } from "./ErrorHandler";

export default class AppContent {
  _handleOnSuccess(response, resolver) {
    return resolver({ data: response.data, status: response.status });
  }

  _handleOnError(error, reject, ignoreTimeout, handleError) {
    return reject(ErrorHandler(error, ignoreTimeout, handleError));
  }

  getNews = (ignoreTimeout, handleError) =>
    new Promise((resolver, reject) =>
      new AxiosAPI(true)
        .setUrl(Config.appContent.ALL_NEWS)
        .setContentType("application/x-www-form-urlencoded")
        .sendGet()
        .then((response) => this._handleOnSuccess(response, resolver))
        .catch((error) =>
          this._handleOnError(error, reject, ignoreTimeout, handleError)
        )
    );
  getCategories = (ignoreTimeout, handleError) =>
    new Promise((resolver, reject) =>
      new AxiosAPI(true)
        .setUrl(Config.appContent.ALL_TUTORIAL_CATEGORIES)
        .setContentType("application/x-www-form-urlencoded")
        .sendGet()
        .then((response) => this._handleOnSuccess(response, resolver))
        .catch((error) =>
          this._handleOnError(error, reject, ignoreTimeout, handleError)
        )
    );
  getVideoTutorials = (ignoreTimeout, handleError) =>
    new Promise((resolver, reject) =>
      new AxiosAPI(true)
        .setUrl(Config.appContent.ALL_TUTORIAL_VIDEOS)
        .setContentType("application/x-www-form-urlencoded")
        .sendGet()
        .then((response) => this._handleOnSuccess(response, resolver))
        .catch((error) =>
          this._handleOnError(error, reject, ignoreTimeout, handleError)
        )
    );
  getPdfTutorials = (ignoreTimeout, handleError) =>
    new Promise((resolver, reject) =>
      new AxiosAPI(true)
        .setUrl(Config.appContent.ALL_TUTORIAL_PDFS)
        .setContentType("application/x-www-form-urlencoded")
        .sendGet()
        .then((response) => this._handleOnSuccess(response, resolver))
        .catch((error) =>
          this._handleOnError(error, reject, ignoreTimeout, handleError)
        )
    );
}

import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import Backdrop from '@mui/material/Backdrop';
import {t} from "../../i18n";
import FCELogo from "../../assets/images/logos/fce.png";
import history from "../../utils/history";
import {MobileTabs, Tabs} from "./UpperManuTabs";
import './UpperMenu.css';

import {setActiveStep, setActiveTab} from "../../redux/menu/menu.actions";


function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const UpperMenu = (props) => {
    const [MenuToggled, setMenuToggled] = useState(false);
    const [width, height] = useWindowSize();
    const Menu = width < 1200 ? MobileTabs : Tabs;

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setMenuToggled(false)
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    console.log(width)
    return (
        <header className="App-header header-interne">
            <div className="logo">
                <div className="col" onClick={() => history.push("/home")}>
                    <img src={FCELogo} className="logo-home" alt="logo"/>
                </div>
            </div>
            <nav role="navigation" onClick={() => {
                setMenuToggled(!MenuToggled);
            }}>
                <div id="menuToggle" className={`menu-burger ${MenuToggled ? 'hidden' : ''}`}><i
                    class="icon-burger"></i></div>
            </nav>
            {width < 1200 ? <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={MenuToggled}
                    onClick={() => {
                        setMenuToggled(false)
                    }}
                >
                    <div ref={wrapperRef} className={`menu o-nav ${MenuToggled ? 'is-inViewport' : ''}`}>
                        <ul>
                            {Menu.map((tab, index) =>
                                index !== 0 ? (
                                    <li>
                                        <a
                                            className={props.activeTab === index ? "active" : ""}
                                            onClick={
                                                tab.subtabs.length > 0
                                                    ? tab.href === "/"
                                                        ? null
                                                        : () => {
                                                            props.setActiveTab(index);
                                                            history.push(tab.href);
                                                        }
                                                    : width < 1200 && tab.activestep != 0 ? () => {
                                                        props.setActiveTab(index);
                                                        history.push(tab.href);
                                                        tab.activeStep
                                                            ? props.setActiveStep(tab.activeStep)
                                                            : props.setActiveStep(0);
                                                    } : () => {
                                                        props.setActiveTab(index);
                                                        history.push(tab.href);
                                                    }
                                            }
                                        >
                                            {t(`Components:upperMenu.${tab.name}`)}
                                        </a>
                                        {tab.subtabs.length > 0 ? (
                                            <ul>
                                                {tab.subtabs.map((subtab, subindex) => (
                                                    <li>
                                                        <a
                                                            onClick={() => {
                                                                props.setActiveTab(index);
                                                                history.push(subtab.href);
                                                                subtab.activeStep
                                                                    ? props.setActiveStep(subtab.activeStep)
                                                                    : props.setActiveStep(0);
                                                            }}
                                                        >
                                                            {t(`Components:upperMenu.${subtab.name}`)}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : null}
                                    </li>
                                ) : null
                            )}
                        </ul>
                    </div>
                </Backdrop> :
                <div ref={wrapperRef} className={`menu o-nav ${MenuToggled ? 'is-inViewport' : ''}`}>
                    <ul>
                        {Menu.map((tab, index) =>
                            index !== 0 ? (
                                <li>
                                    <a
                                        className={props.activeTab === index ? "active" : ""}
                                        onClick={
                                            tab.subtabs.length > 0
                                                ? tab.href === "/"
                                                    ? null
                                                    : () => {
                                                        props.setActiveTab(index);
                                                        history.push(tab.href);
                                                    }
                                                : width < 1200 && tab.activestep != 0 ? () => {
                                                    props.setActiveTab(index);
                                                    history.push(tab.href);
                                                    tab.activeStep
                                                        ? props.setActiveStep(tab.activeStep)
                                                        : props.setActiveStep(0);
                                                } : () => {
                                                    props.setActiveTab(index);
                                                    history.push(tab.href);
                                                }
                                        }
                                    >
                                        {t(`Components:upperMenu.${tab.name}`)}
                                    </a>
                                    {tab.subtabs.length > 0 ? (
                                        <ul>
                                            {tab.subtabs.map((subtab, subindex) => (
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            props.setActiveTab(index);
                                                            history.push(subtab.href);
                                                            subtab.activeStep
                                                                ? props.setActiveStep(subtab.activeStep)
                                                                : props.setActiveStep(0);
                                                        }}
                                                    >
                                                        {t(`Components:upperMenu.${subtab.name}`)}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : null}
                                </li>
                            ) : null
                        )}
                    </ul>
                </div>}
        </header>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setActiveTab: (tab) => {
        dispatch(setActiveTab(tab));
    },
    setActiveStep: (step) => {
        dispatch(setActiveStep(step));
    },
});
const mapStateToProps = (state) => ({
    activeTab: state.Menu.activeTab,
});

export default connect(mapStateToProps, mapDispatchToProps)(UpperMenu);

const AppContentActionTypes = {
  GET_ALL_NEWS: "GET_ALL_NEWS",
  GET_ALL_CATEGORIES: "GET_ALL_CATEGORIES",
  GET_ALL_VIDEO_TUTORIALS: "GET_ALL_VIDEO_TUTORIALS",
  GET_ALL_PDF_TUTORIALS: "GET_ALL_PDF_TUTORIALS",
  TOGGLE_VIDEO_MODAL: "TOGGLE_VIDEO_MODAL",
  SELECT_CATEGORY: "SELECT_CATEGORY",
  SET_SELECTED_NEWS: "SET_SELECTED_NEWS",
};

export default AppContentActionTypes;

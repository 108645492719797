import { actionCreator } from "../../utils/generators";
import MenuActionTypes from "./menu.types";

export const setActiveTab = (tabIndex) => ({
  type: actionCreator("req", MenuActionTypes.SET_ACTIVE_TAB),
  payload: tabIndex,
});

export const setActiveStep = (step) => ({
  type: actionCreator("req", MenuActionTypes.SET_ACTIVE_STEP),
  payload: step,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    homeSchema: {
        helpPossibility: "Une possibilité d’accompagnement personnalisé",
        firstContentDescription:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata",
        firstContentActionTitle: " Faite votre Bilan",
    },
    whoAreWe: {
        whoAreWe: "Qui Sommes Nous ?",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam turpis quis risus vehicula, quis faucibus arcu tempus. Phasellus nec turpis ut diam placerat ultricies.",
        ourObjectives: "Nos Objectives",
        objectifDescription:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        visionDescription:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        ourVisions: "Nos Visions",
        learnMore: "En savoir plus",
    },
    homeSlider: {
        havingAnIdeaOrABusinessProject:
            "Vous êtes un porteur d'idée ou de projet",
        description1:
            "Notre programme d'accompagnement vous apportera tout le soutien dont vous aurez besoin pour créer et pérenniser votre entreprise : durant la période de pré-lancement, lors du démarrage, ainsi qu’un suivi de votre projet une fois lancé.",
        fce: "Vous êtes un auto-entrepreneur ou une très petite entreprise (TPE)",
        isAtYourDisposal:
            "Vous cherchez à accroître votre volume d'affaires, à améliorer votre productivité ou à maitriser vos besoins de trésorerie ? \n" +
            "Nous vous invitons à consulter notre plateforme de formation. Vous pouvez également faire appel à nos services de mentoring et de networking.\n",
        fromStartUntilCreation:
            " Vous cherchez à améliorer vos compétences entrepreneuriales et managériales",
        multipleHelpsAndDocumentation:
            " Nous mettons à votre disposition un catalogue de formations riche et ciblé. ",
        titleLast:
            "Fondation Création d'Entreprises",
        socialNetworkingSupport: "À travers sa mission statutaire, ses valeurs de proximité, d’engagement et de rigueur, la Fondation Création d’Entreprises du groupe BCP, apporte des dispositifs de soutien et d'accompagnement aux porteurs de projets et aux TPE à l'échelle nationale.",
        socialNetworkingSupport2: "La FCE assure un accompagnement personnalisé en pré et en post- création, prodigue aux entrepreneurs de la formation managériale et entrepreneuriale, contribue à la pérennisation des TPE, à travers son offre de mentorat, de réseautage et de networking.  ",
        more: "En savoir plus !",
        assiste: "Demande d’accompagnement"
    },
    stepsSectionSchema: {
        doYouHaveAnIdea:
            " Vous êtes un porteur d’idée et vous êtes toujours en phase de réflexion, renseignez le",
        helpForm: "formulaire d’accompagnement",
        yourIdeaiHasGrown:
            "Votre idée a mûri, prend forme et se transforme en projet,renseignez le",
        businessModel: "business modèle",
        youHaveAPlan:
            "Votre projet est défini les projections financières sont établies et affinées, renseignez le",
        businessPlan: "Business plan",
        projectYourSelf: " Projetez-vous en faisant une",
        simulation: "simulation",
        ofYourFinance: "de votre financement",
    },
    footer: {
        knowUs: " Qui sommes-nous ?",
        FAQ: "Foire aux questions",
        contactUs: "Nous contacter",
        CU: "Conditions d'utilisation",
    },
    videoTech: {
        title: "Vidéothèque",
        description:
            "La Fondation Création d’Entreprises prône l’importance de l’accompagnement des entrepreneurs et le considère comme un élément crucial dans la réussite de chaque projet. Elle propose à titre gratuit\n" +
            "\n" +
            "un large choix de contenu formateur couvrant tous les aspects liés à l’entrepreneuriat : vidéos e-learning, replay de webinaires, guides en e-book … ",
        accessVideoTech: "Accéder à la vidéothèque",
    },
    testemonies: {
        successStories: "Success Stories",
        description:
            "Ce qui anime les experts accompagnateurs de la Fondation Création d’Entreprises est le parcours des porteurs d’idées et des TPE : passer de l’idée au marché est une aventure passionnante. \n Mille Mercis pour votre confiance !",
    },
    partners: {
        ourPartners: "Nos Partenaires",
    },
    map: {
        title: "La présence géographique de la FCE",
        description:
            "La Fondation est présente partout au Maroc ,à travers un fort réseau composé de neuf bureaux régionaux. Vous pouvez contacter dès maintenant le représentant le plus proche de chez vous.",
        learnMore: "En Savoir plus !",
    },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /*categories: "Categories",
  AssistanceForm: "Formulaire d'accompagnement",
  BusinessModel: "Business Model",
  BusinessPlan: "Business Plan",
  FinancialFile: "Dossier de financement",
  Creation: "Creation",
  ToolsBox: "Boite à Outils",
  Accompaniment: "Accompagnement",*/
  areYouBigOrSmallCompany:
    "Vous êtes porteur de projets, auto-entrepreneur ou une très petite entreprise (TPE)",
  areYouBigOrSmallCompanyDetails:
    "Vous cherchez à accroitre votre courant d’affaires, à améliorer votre productivité ou encore à adapter votre cycle de financement, une palette de service a été conçue pour répondre à votre besoin.",
  upgradeYourSkills: " Vous êtes en quête d’améliorer vos compétences",
  managerial: " entrepreneuriales et managériales",
  businessTutoring: "Formation Entreprenarial",
  businessTutoringDetails:
    "  La Fondation Création d’Entreprises prône l’importance de la formation et de l’accompagnement des entrepreneurs et le considère comme un élément crucial dans la réussite de chaque projet. Elle propose à titre gratuit\n" +
      "\n" +
      "un large choix de contenu formateur couvrant tous les aspects liés à l’entrepreneuriat : vidéos e-learning, replay de webinaires, guides en e-book … ",
  seeGuides: "Voir Guides",
  seeVideos: "Voir capsules videos",
  managerialTutoring: "Formation Managerial ",
  managerialTutoringDetails:
    " Vous êtes en pleine activité entrepreneuriale et vous souhaitez l’accompagner par votre perfectionnement professionnel et celui de votre équipe, consulter notre catalogue de formation E-learning et nos guides métiers.",
  mentoringAndNetworking: "Mentorat, réseautage et market place",
  monitoringNetwork: " Le réseau de Mentorat",
  monitoringNetworkDetails:
    "L’objectif de l’offre de mentorat de la FCE est d’accompagner des TPE nouvellement créées et les porteurs de projet dans la création, le démarrage et le développement des activités de leurs entreprises en mettant à leur disposition un réseau de mentorat. Ce réseau permet notamment :",
  exchange:
    "  D’échanger avec des entrepreneurs séniors sur des problématiques communes et des coups durs rencontrés.",
  sharing: " De partager les bons plans et les astuces pour aller plus vite.",
  findPotentialClients: "De trouver des potentiels clients. ",
  download: " Pour Télécharger ",
  mentoringChart: "la Charte du Mentorat,",
  clickHere: "Cliquer ici",
  becomeMentored: "Devenez Mentoré",
  becomeMentoredDetails:
    " Vous voulez accélérer votre développement en tant qu’entrepreneur et vous souhaitez vous ouvrir à d’autres expériences entrepreneuriales réussies, sans perdre votre autonomie de décision. Si vous êtes une entreprise TPE existante depuis plus d’un an et moins de 5 ans, inscrivez-vous :",
  becomeMentore: "Devenez Mentor",
  becomeMentoreDetails:
    "Vous êtes un chef d’entreprise, un dirigeant d’une affaire, avec une expérience probante de plus de 5 ans, dans le monde de l’entreprenariat et vous souhaitez devenir mentor, vous êtes invités à remplir le formulaire de candidature, ci-après :",
  joinClub: " Rejoindre un club d’entrepreneurs pour développer son réseau",
  joinClubDetails:
    " Les clubs d’entrepreneurs au Maroc sont orientés business, mais certains clubs prônent également le partage de compétences. La majorité des clubs d’entrepreneurs sont chapeautés par les chambres de commerce, d’industrie et de services, les chambres de l’Artisanat ou de l’agriculture les fédérations sectorielles, ou encore les fédérations sectorielles. On peut aussi en trouver des clubs ou associations orientés vers des secteurs d’activités, tels que les fédérations sectorielles ou encore des groupements visant des catégories socioprofessionnelles bien déterminées, tels que les Associations des femmes entrepreneures ou encore la Diaspora. Il est fortement recommandé d’adhérer à ces réseaux d’affaires, et d’y investir son temps, au vu des opportunités qu’ils offrent à toute entreprise désirante d’augmenter son capital relationnel et son courant d’affaires.",
  marketPlace: "Market Place",
  marketPlaceDetail:
    " Une marketplace est une place de marché internet dont l’objectif est de mettre en relation des vendeurs et des acheteurs. Cela implique donc une grande diversité d’assortiment et une réelle compétitivité des prix. Les placemarket sont des accélérateurs de votre courant d’affaires, néanmoins, pour que votre présence soit profitable, il faut maîtriser parfaitement certaines règles. Sans cela, votre présence sur les Marketplace peut porter un coup pour votre business.",
  goldIn: "Gold In",
  goldInDescription:
    "  Goldin.ma est une Marketplace E-commerce de bijouterie, joaillerie et horlogerie du Maroc. Elle propose une large sélection de bijoux en or jaune et en or blanc les plus classiques, mais aussi l’or rose, grande tendance ces dernières années. L’or reste le métal le plus utilisé tant pour sa qualité que son esthétique indéniable.",
  anou: "The Anou",
  anouDetails:
    "Www.theanou.com (Anou = “Puit” en Tachelhit), est la seule plateforme e-commerce au Maroc qui, non seulement appartient aux artisans, mais est aussi gérée par eux-mêmes. C’est un projet à fort impact social qui vise à mettre fin à l’exploitation abusive des intermédiaires, en permettant à l’artisan de vendre directement au client. Anou fut reconnue en 2020 par Ashoka comme l’un des projets les plus impactant de la région MENA (Middle East North Africa). C’est aujourd’hui une référence du commerce éthique à l’international.",
  myTindy: "MyTindy",
  myTindyDetails:
    "  MyTindy est la Marketplace spécialisée dans l’artisanat marocain et qui a pour vocation de permettre à l’artisan d’offrir la possibilité à n’importe quel client d’acheter des produits 100% marocains en ligne",
  epicerieVerte: "Epicerie Verte",
  epicerieVerteDetails:
    "Epicerie Verte est la première Marketplace en ligne spécialisée dans les produits bio, naturels et du terroir au Maroc, ainsi que le 1er transporteur écologique de marchandises du pays. Le but de la plateforme www.EpicerieVerte.ma est de valoriser le Made-in-Morocco artisanal et éco-responsable aussi bien localement qu’à l’international. Epicerie Verte c’est un modèle basé sur le développement durable, en respectant les principes d’intermédiation équitable. Il s’agit d’une plateforme à fort potentiel, faisant partie d’un segment de sites marchands premium, de vente des produits de terroir, d’artisanat léger et de cosmétiques de qualité.",
  chicIntemporel: "Chic Intemporel",
  chicIntemporelDetails:
    " Chic Intemporel est une Marketplace de niche, à visée économique sociale et solidaire. Il s’agit d’une plateforme ciblant l’export et le marché marocain. Les marchés prioritaires sont l’Europe Francophone (France, Belgique et Suisse) ainsi que le Maroc. La market place est en bi-lingue : Français et Anglais et propose 5 devises : Dirhams, Euros, Libre, Dollars et EAU. Cette Marketplace élégante et épurée est dédiée aux créateurs, artisans, coopératives de talent marocains, avec un parcours d’achats facilité et surtout un accompagnement permanent.",
  jumia: "Jumia",
  jumiaDetails:
    " Leader marocain de la vente en ligne depuis 8 ans, Jumia participe à la digitalisation et à la modernisation des habitudes d’achat du marocain et fournit aux vendeurs une plateforme efficace et complète pour vendre sur internet.",
  tribaliste: "Tribaliste",
  tribalisteDetails:
    " Tribaliste est une boutique en ligne totalement gratuite pour l’artisan, spécialisée dans l’artisanat Marocain de décoration d’intérieur. L’objectif de Tribaliste est de permettre aux artisans de vendre leurs produits en ligne facilement en éliminant toutes les barrières du digital. Les clients de Tribaliste.com sont des passionnés de déco à la recherche de produits authentiques d’artisanat. Ce sont des particuliers mais également des professionnels (architectes d’intérieur, designers…)",
  glovo: "Glovo",
  glovoDetails:
    " Glovo joue un rôle d’accélérateur d’opportunités économiques pour les glovers au Maroc. La plate-forme leur propose de nouvelles sources de revenus et favorise l’employabilité des jeunes en milieu urbain. Tous les domaines sont concernés : restauration, shopping express, stores high-techs, cadeaux en tous genres…",
  facebookBusiness: "Facebook Business",
  facebookBusinessDetails:
    "Marketplace sur Facebook permet aux utilisateurs de découvrir, d’acheter et de vendre des articles. Les utilisateurs peuvent parcourir les annonces, chercher des articles à vendre dans leur région ou trouver des produits qui peuvent être livrés.",
  richeCatalogue:
    " Un catalogue de formation riche et ciblé est mis à votre disposition.",
  videoTech: "Vidéothèque",
  videoTechDetails:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  videos: "Videos",
  webinars: "Webinaires ",
  webinarsDetails:
    " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  guides: "Guides",
  lookingToImproveUrSelf:
    "Vous êtes en quête d’améliorer vos compétences entrepreneuriales et managériales",
  tutorialCatalogue:
    "un catalogue de formation riche et ciblé est mis à votre disposition.",
  improveCompetences: " Vous êtes en quête d’améliorer vos compétences",
  managerialAndEntreprenorial: " entrepreneuriales et managériales",
  targetedCatalogue:
    " un catalogue de formation riche et ciblé est mis à votre disposition.",
  federatorsList: "Liste des federations sectorielles",
};

//#region Imports 
import { put, call } from 'redux-saga/effects';
import LoaderActionTypes from '../loader/loader.types';
//#endregion
//******************************************* */

/**
 * @name sagaWithLoader
 * @description Function to add display/Hide Handling of Loader when exchanging requests 
 */
export function* sagaWithLoader(saga, action) {
  yield put({ type: LoaderActionTypes.SHOW_LOADER });
  yield call(saga, action);
  yield put({ type: LoaderActionTypes.HIDE_LOADER });
}

import types from './messages.types';

const defaultState = {
  shown: false,
  severity: null,
  text: null,
};

const messagesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_MESSAGE:
      return {
        ...state,
        shown: true,
        text: action.payload.text,
        severity: action.payload.severity,
        hasCancelBtn: action.payload.hasCancelBtn,
        validateTitle: action.payload.validateTitle,
        handleValidate: action.payload.handleValidate,
      };
    case types.HIDE_MESSAGE:
      return {
        shown: false,
      };
    default:
      return state;
  }
};

export default messagesReducer;

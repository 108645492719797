import { t } from "../../i18n";
import MessagesActionTypes from "../../redux/messages/messages.types";
//import { actionCreator } from "../../utils/generators";

const _defaultError = () => ({
  type: MessagesActionTypes.SHOW_MESSAGE,
  payload: {
    severity: "ERROR",
    text: t("Errors:DEFAULT_ERROR"),
    validateTitle: t(`Components:close`),
  },
});

const _timeoutError = () => ({
  type: MessagesActionTypes.SHOW_MESSAGE,
  payload: {
    severity: "ERROR",
    text: t("Errors:timeoutMessage"),
    validateTitle: t(`Components:close`),
  },
});

const _handleFunctional = (error) => {
  return {
    type: MessagesActionTypes.SHOW_MESSAGE,
    payload: {
      severity: "ERROR",
      text:
        t(`Errors:${error.response.data.code}`) !== error.response.data.code
          ? t(`Errors:${error.response.data.code}`)
          : t("Errors:defaultErrorMessage"),
      validateTitle: t(`Components:close`),
    },
  };
};

/*const _handleSessionExpiration = () => ({
  type: actionCreator("req", LoginActionTypes.LOGOUT_ACTION),
});*/

export const ErrorHandler = (error, ignoreTimeout, handleError = true) => {
  if (!handleError) {
    return { type: "FAIL" };
  }
  if (
    error.code === "ECONNABORTED" ||
    (error.response && error.response.status === 408)
  ) {
    return !ignoreTimeout ? _timeoutError() : { type: "TIME_OUT" };
  }
  if (!error.response) {
    return _defaultError();
  }
  /*if (error.response.status === 401 || error.response.status === 403) {
    return _handleSessionExpiration();
  }*/
  if (
    error.response.status === 400 &&
    error.response.data.code === "USER_CAN_BE_RECOVERED_BY_USERNAME"
  ) {
    return { type: "RECOVER_BY_USERNAME" };
  }
  if (
    error.response.status === 400 &&
    error.response.data.code === "USER_CAN_BE_RECOVERED_BY_EMAIL"
  ) {
    return { type: "RECOVER_BY_EMAIL" };
  }
  if (
    error.response.status === 400 &&
    error.response.data.code === "INIT_PHONE_ALREADY_EXISTS"
  ) {
    return { type: "REDIRECT_TO_FORM" };
  }
  if (error.response.status === 400) {
    return _handleFunctional(error);
  }
  return _defaultError();
};

export const LoginErrorHandler = (error) => {
  if (
    error.code === "ECONNABORTED" ||
    (error.response && error.response.status === 408)
  ) {
    return _timeoutError();
  }
  if (!error.response) {
    return _defaultError();
  }
  if (
    error.response.status === 401 ||
    error.response.status === 403 ||
    error.response.status === 400
  ) {
    return _handleFunctional(error);
  }
  return _defaultError();
};

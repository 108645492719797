import Components from "../../components/locales/fr";
import Errors from "../../services/apis/Errors/locales/fr";
import Form from "../../features/Form/locales/fr";
import Home from "../../features/Home/locales/fr";
import Tutorials from "../../features/TutorialSection/Locales/fr";
import HelpProgram from "../../features/HelpProgram/Locales/fr";
import WhoAreWe from "../../features/WhoAreWe/locales/fr";
import News from "../../features/News/locales/fr";
import Network from "../../features/NetworkAndMonitoring/locales/fr";
import Accompaniment from "../../features/HelpProgram/Locales/fr";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Components,
  Errors,
  Form,
  Home,
  Tutorials,
  HelpProgram,
  WhoAreWe,
  News,
  Network,
  Accompaniment,
};

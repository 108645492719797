import React from "react";
import Loader from "../../../assets/svgs/LoaderSvg";

const loaderArt = (props) => (
  <div className="content">
    <div className=" loader loader5">
      <Loader />
    </div>
  </div>
);

export default loaderArt;

import React from "react";

const LoaderSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "auto", background: "none" }}
      width="217"
      height="217"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
    >
      <circle
        cx="50"
        cy="50"
        r="38"
        fill="none"
        stroke="#e4812f"
        strokeDasharray="59.690260418206066 59.690260418206066"
        strokeLinecap="round"
        strokeWidth="2"
      >
        <animateTransform
          attributeName="transform"
          dur="1.0204081632653061s"
          keyTimes="0;1"
          repeatCount="indefinite"
          type="rotate"
          values="0 50 50;360 50 50"
        ></animateTransform>
      </circle>
      <circle
        cx="50"
        cy="50"
        r="35"
        fill="none"
        stroke="#0a0a0a"
        strokeDasharray="54.97787143782138 54.97787143782138"
        strokeDashoffset="54.978"
        strokeLinecap="round"
        strokeWidth="2"
      >
        <animateTransform
          attributeName="transform"
          dur="1.0204081632653061s"
          keyTimes="0;1"
          repeatCount="indefinite"
          type="rotate"
          values="0 50 50;-360 50 50"
        ></animateTransform>
      </circle>
    </svg>
  );
};

export default LoaderSvg;

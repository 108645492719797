export default class Config {
  //#region Configuration Timers
  static timeOutLow = 20000;
  static timeOutMiddle = 40000;
  static timeOutHigh = 60000;
  //#endregion

  //#region Configure Base Url

  //static BASE_URL = "http://192.168.0.100:4001/api/";
  //static BASE_URL = "http://192.168.100.70:4001/api/";
  //static BASE_URL = "http://192.168.66.243/apighm/api/";
  //static BASE_URL = "http://10.212.100.244/apighm/api/";
  //static BASE_URL = "http://192.168.100.70:4001/api/";
  //static BASE_URL = "https://172.19.76.12:8443/apighm/api/";
  //static BASE_URL = "http://localhost:4001/api/";
  static BASE_URL = "https://wenfcetest-fceapi.azuremicroservices.io/api/";//Azure Cloud


  //#endregion
  //**************************************************** */

  static requests = {
    ALL_CITIES: "support/getAllCities",
    ALL_SELECTIONS: "support/getAllSelections",
    INIT_REQUEST: "support/initSupportRequest",
    VERIFY_INIT_REQUEST: "support/initSupportRequest/verify",
    ADD_PERSONAL_INFORMATION: "support/addPersonalInformation",
    ADD_REQUEST: "support/addSupportRequest",
  };
  static appContent = {
    ALL_NEWS: "appContent/getAllNews",
    ALL_TUTORIAL_CATEGORIES: "appContent/getAllTutorialCategories",
    ALL_TUTORIAL_VIDEOS: "appContent/getAllVideoTutorials",
    ALL_TUTORIAL_PDFS: "appContent/getAllPdfTutorials",
  };







}

//#region Imports 
import { all, call } from "redux-saga/effects";
import { requestSagas } from "./request/request.sagas";
import { appContentSagas } from "./appContent/appContent.sagas";
//#endregion
//****************************************** */

//#region Root Saga where to call your sagas
export default function* rootSaga() {
  yield all([
    call(requestSagas),
    call(appContentSagas),
  ]);
}
//#endregion
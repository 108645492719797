import types from './loader.types';

const defaultState = {
  shown: false,
};

const loaderReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_LOADER:
      return {
        shown: true,
      };
    case types.HIDE_LOADER:
      return {
        shown: false,
      };
    default:
      return state;
  }
};

export default loaderReducer;

import { put, takeLatest, call, all } from "redux-saga/effects";
import AppContent from "../../services/apis/appContent";
import AppContentActionTypes from "./appContent.types";
import { actionCreator } from "../../utils/generators";
import { sagaWithLoader } from "../template/sagaWithLoader";

function* getAllNewsFeature() {
  try {
    const allNews = yield call(new AppContent().getNews);
    yield put({
      type: actionCreator("res", AppContentActionTypes.GET_ALL_NEWS),
      payload: allNews.data,
    });
  } catch (error) {
    yield all([
      put(error),
      put({ type: actionCreator("fail", AppContentActionTypes.GET_ALL_NEWS) }),
    ]);
  }
}
function* getAllCategoriesFeature() {
  try {
    const allCategories = yield call(new AppContent().getCategories);
    yield put({
      type: actionCreator("res", AppContentActionTypes.GET_ALL_CATEGORIES),
      payload: allCategories.data,
    });
  } catch (error) {
    yield all([
      put(error),
      put({
        type: actionCreator("fail", AppContentActionTypes.GET_ALL_CATEGORIES),
      }),
    ]);
  }
}
function* getAllVideoTutorialsFeature() {
  try {
    const allVideoTutorials = yield call(new AppContent().getVideoTutorials);
    yield put({
      type: actionCreator("res", AppContentActionTypes.GET_ALL_VIDEO_TUTORIALS),
      payload: allVideoTutorials.data,
    });
  } catch (error) {
    yield all([
      put(error),
      put({
        type: actionCreator(
          "fail",
          AppContentActionTypes.GET_ALL_VIDEO_TUTORIALS
        ),
      }),
    ]);
  }
}
function* getAllPdfTutorialsFeature() {
  try {
    const allPdfTutorials = yield call(new AppContent().getPdfTutorials);
    yield put({
      type: actionCreator("res", AppContentActionTypes.GET_ALL_PDF_TUTORIALS),
      payload: allPdfTutorials.data,
    });
  } catch (error) {
    yield all([
      put(error),
      put({
        type: actionCreator(
          "fail",
          AppContentActionTypes.GET_ALL_PDF_TUTORIALS
        ),
      }),
    ]);
  }
}

export function* appContentSagas() {
  yield takeLatest(
    actionCreator("req", AppContentActionTypes.GET_ALL_NEWS),
    sagaWithLoader,
    getAllNewsFeature
  );
  yield takeLatest(
    actionCreator("req", AppContentActionTypes.GET_ALL_CATEGORIES),
    sagaWithLoader,
    getAllCategoriesFeature
  );
  yield takeLatest(
    actionCreator("req", AppContentActionTypes.GET_ALL_VIDEO_TUTORIALS),
    sagaWithLoader,
    getAllVideoTutorialsFeature
  );
  yield takeLatest(
    actionCreator("req", AppContentActionTypes.GET_ALL_PDF_TUTORIALS),
    sagaWithLoader,
    getAllPdfTutorialsFeature
  );
}

//#region Imports
import React from "react";
import { connect } from "react-redux";

import Modal from "../Ui/CustomModal/Modal";
import { hideMessage } from "../../redux/messages/messages.actions";
import { t } from "../../i18n";
//#endregion
//*************************************** */


/**
 * @name MessageHandler
 * @description Function Component To Handle (Show/) Messages in Modal (Err,Warning)
 */
//#region Main Function Component
const MessageHandler = (props) =>
  props.shown ? (
    <div>
      <Modal
        isNotBtn
        title={t(`Components:messageHandler.${props.severity}`)}
        hasValidateBtn={true}
        onCancel={props.hideMessage}
        severity={props.severity}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "16px",
          }}
        >
          {t(`Component:${props.text}`)}
        </p>
      </Modal>
    </div>
  ) : null;
//#endregion



//#region Redux Connection 
const mapDispatchToProps = (dispatch) => ({
  hideMessage: () => {
    dispatch(hideMessage());
  },
});
const mapStateToProps = (state) => ({
  shown: state.Messages.shown,
  text: state.Messages.text,
  severity: state.Messages.severity,
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageHandler);
//#endregion
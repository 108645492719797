//#region  Imports 
import { combineReducers } from "redux";

import messagesReducer from "./messages/messages.reducer";
import loaderReducer from "./loader/loader.reducer";
import requestReducer from "./request/request.reducer";
import menuReducer from "./menu/menu.reducer";
import appContentReducer from "./appContent/appContent.reducer";

//#endregion
//********************************************** */

//#region Root Reducer 
const appReducer = combineReducers({
  Messages: messagesReducer,
  Loader: loaderReducer,
  Request: requestReducer,
  Menu: menuReducer,
  AppContent: appContentReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};
//#endregion


export default rootReducer;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    title: "Qui sommes-nous ?",
    history:
        "Créée en 1991, la Fondation Création d’Entreprises (FCE) du groupe BCP , est une association à but non lucratif reconnue d’utilité publique le 27 juin 2001. ",
    publicUtility: "",
    mission: "Mission",
    missionDetail1: "À travers sa mission statutaire, ses valeurs de proximité, d’engagement et de rigueur, la Fondation Création d’Entreprises du groupe BCP apporte des dispositifs de soutien et d'accompagnement aux porteurs des projets et des TPE à l'échelle nationale.",
    missionDetail2: "La FCE apporte aux entrepreneurs un accompagnement personnalisé en pré et en post-création et des programmes de formation managériale et entrepreneuriale. Elle contribue également à la pérennisation des TPE à travers son offre de mentorat, de réseautage et de networking.",
    vastNetwork: "Un réseau étendu",
    vastNetworkDetails:
        " Pour se positionner au plus près des porteurs de projets et des TPE, la FCE s’est dotée de 9 bureaux régionaux, qui lui permettent de leur apporter son assistance et son accompagnement à travers un ensemble de services non financiers.",
    strongPartners:
        " Des partenaires forts de création et de développement d'entreprises",
    strongPartnersDetails:
        "Pour mettre toutes les chances de réussite du côté des porteurs de projets et des TPE, la FCE a noué des partenariats avec les principaux acteurs de l’écosystème entrepreneurial Marocain ainsi que d’autres opérateurs nationaux et internationaux d’envergure : Centres Régionaux d’Investissement (CRI), Agence Nationale de Promotion de l’Emploi et des Compétences (ANAPEC), Chambres de Commerce, d’Industrie et de Services, Chambres d’Artisanat et établissements d’enseignement supérieur.\n" +
        "La FCE participe activement aux programmes initiés par ses partenaires et se positionne, grâce à la pertinence de son programme d’accompagnement, comme un incubateur accélérateur de création d’entreprises.\n",
    engagement: " Un engagement synonyme d’actions",
    engagementDetails:
        " A travers son offre d'accompagnement, la FCE appuie annuellement près d'un millier de porteurs de projets. Elle porte un intérêt particulier à la formation, à travers l’organisation d’une centaine de séminaires (en ligne et en présentiel) ou encore la mise en place d’une plateforme de e-learning à la fois riche et ludique, et organise des cessions de mentorats et de networking au profit des porteurs de projets et des TPE nouvellement créées.",
};

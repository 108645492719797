const RequestActionTypes = {
  GET_ALL_SELECTIONS: "GET_ALL_SELECTIONS",
  GET_ALL_CITIES: "GET_ALL_CITIES",
  INIT_REQUEST: "INIT_REQUEST",
  VERIFY_INIT_REQUEST: "VERIFY_INIT_REQUEST",
  ADD_PERSONAL_INFORMATION: "ADD_PERSONAL_INFORMATION",
  ADD_REQUEST: "ADD_REQUEST",
  SET_STEP: "SET_STEP",
  SET_INER_STEP: "SET_INER_STEP",
  SET_STEP_TWO_DATA: "SET_STEP_TWO_DATA",
  SET_STEP_THREE_DATA: "SET_STEP_THREE_DATA",
};

export default RequestActionTypes;

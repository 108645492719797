import produce, { enableES5 } from "immer";
import MenuActionTypes from "./menu.types";
import { actionCreator } from "../../utils/generators";

enableES5();
const defaultState = {
  isLoading: false,
  error: null,
  activeTab: 0,
  activeStep: 1,
};

const menuReducer = produce((draft, action) => {
  switch (action.type) {
    case actionCreator("req", MenuActionTypes.SET_ACTIVE_TAB):
      draft.activeTab = action.payload;
      break;
    case actionCreator("req", MenuActionTypes.SET_ACTIVE_STEP):
      draft.activeStep = action.payload;
      break;
    default:
      break;
  }
}, defaultState);

export default menuReducer;

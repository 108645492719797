// eslint-disable-next-line import/no-anonymous-default-export
export default {
    BRANCH_ADDED_SUCCESSFULLY: "Agence bien ajoutée",
    BRANCH_DELETED_SUCCESSFULLY: "Agence bien supprimée",
    BRANCH_DISABLED_SUCCESSFULLY: "Agence désactivé avec succes",
    BRANCH_ENABLED_SUCCESSFULLY: "Agence bien activée",
    BRANCH_EDITED_SUCCESSFULLY:
        "Les modifications ont été enregistrés avec succès",
    AGENT_ADDED_SUCCESSFULLY: "Agent bien ajouté",
    AGENT_DELETED_SUCCESSFULLY: "Agent bien supprimé",
    AGENT_DISABLED_SUCCESSFULLY: "Agent bien desactivé",
    AGENT_ENABLED_SUCCESSFULLY: "Agent bien activé",
    AGENT_EDITED_SUCCESSFULLY:
        "Les modifications ont été enregistrés avec succès",
    PASSWORD_CHANGED_SUCCESSFULLY: "Mot de passe bien changé",
    USER_REGISTERED_SUCCESSFULLY: "Utilisateur bien enregistré",
    USER_DISCONNECTED_SUCCESSFULLY: "Utilisateur bien déconnecter",
    BANK_ADDED_SUCCESSFULLY: "Filiale ajoutée avec succès",
    USER_RECOVERED_SUCCESSFULLY: "Utilisateur restauré avec succès",
    USER_DELETED_SUCCESSFULLY: "Utilisateur supprimé avec succès",
    USER_ENABLED_SUCCESSFULLY: "Utilisateur activé avec succès",
    USER_DISABLED_SUCCESSFULLY: "Utilisateur desactivé avec succès",
    USER_EDITED_SUCCESSFULLY: "Les modifications ont été enregistrés avec succès",
    AGENT_RESET_SUCCESSFULLY: "Agent réinitialisé avec succès",
    REQUEST_ADDED_SUCCESSFULLY: "Demande FCE Ajoutée avec succès",
    RESET_PASSWORD_LINK_SENT_SUCCESSFULLY:
        "Link du changement de mot de passe bien envoyé",

    messageHandler: {
        ERROR: "Erreur",
        SUCCESS: "Succès",
    },
    video: {
        homeTitle: "Videos à la une ",
        listTitle: "Liste des Videos de Formation",
        assistanceFormTitle: "Formulaire d'assistance: Formations Video ",
    },
    news: {
        homeTitle: "Actualités",
        listTitle: "Actualitées",
        readMore: "Lire Plus",
    },

    upperMenu: {
        whoAreWe: "Qui sommes nous ?",
        home: "Acceuil",
        newsAndEvents: "Actualités et évènements​",
        networkingAndMentoring: "Réseautage et Mentoring​",
        supportProgram: "Programme d’accompagnement",
        assistanceForm: "Demande d’accompagnement",
        businessModel: "Business Model​​",
        businessPlan: "Business Plan​",
        fundingFile: "Dossier de financement",
        creation: "Demarches de création",
        toolbox: "Boite à outils",
        training: "Formation",
        video: "Vidéothèque",
        PDF: "Guides pratiques",
        entrepreneursJourney: "Parcours de l'entrepreneur",
    },
    videoList: {
        searchVideoList: "Recherche dans la liste des videos",
    },
    pdfList: {
        searchPdfList: "Recherche dans la liste des PDFs",
        clickHere: "Cliquer ici",
        toDownload: "Téléchargez",
        download: "Télécharger",
    },
    Selector: {
        categories: "Categories",
        AssistanceForm: "Formulaire d'accompagnement",
        BusinessModel: "Business Model",
        BusinessPlan: "Business Plan",
        FinancialFile: "Dossier de financement",
        Creation: "Creation",
        ToolsBox: "Boite à Outils",
        MarketPlace: "MarketPlace",
        Accompaniment: "Accompagnement",
        EntrepreneurialTraining: "Formation Entrepreneuriale",
        ManagerialTraining: "Formations managériales",
        Webinar: "Webinar",
    },
    Modal: {
        close: "Fermer",
        cancel: "Annuler",
        confirm: "Confirmer",
    },
};

export const Tabs = [
    {
        name: "home",
        href: "/home",
        subtabs: [],
    },
    {
        name: "whoAreWe",
        href: "/whoarewe",
        subtabs: [],
    },
    {
        name: "newsAndEvents",
        href: "/news",
        subtabs: [],
    },
    {
        name: "supportProgram",
        href: "/helpprogram",
        subtabs: [
            {
                name: "entrepreneursJourney",
                href: "/helpprogram",
            },
            {
                name: "assistanceForm",
                href: "/form",
            },
            {
                name: "businessModel",
                href: "/helpprogram",
                activeStep: 1,
            },
            {
                name: "businessPlan",
                href: "/helpprogram",
                activeStep: 2,
            },
            {
                name: "fundingFile",
                href: "/helpprogram",
                activeStep: 3,
            },
            {
                name: "creation",
                href: "/helpprogram",
                activeStep: 4,
            },
            /* {
               name: "toolbox",
               href: "/helpprogram",
               activeStep: 5,
             },*/
        ],
    },
    {
        name: "training",
        href: "/tutorialhome",
        subtabs: [
            {
                name: "video",
                href: "/videoTutorials",
            },
            {
                name: "PDF",
                href: "/pdfTutorials",
            },
        ],
    },
    {
        name: "networkingAndMentoring",
        href: "/networkandmonitoring",
        subtabs: [],
    },
];

export const MobileTabs = [
    {
        name: "home",
        href: "/home",
        activeStep: 0,
        subtabs: [],
    },
    {
        name: "whoAreWe",
        href: "/whoarewe",
        activeStep: 0,
        subtabs: [],
    },
    {
        name: "newsAndEvents",
        href: "/news",
        activeStep: 0,
        subtabs: [],
    },

    {
        name: "entrepreneursJourney",
        href: "/helpprogram",
        activeStep: 0,
        subtabs: [],
    },
    {
        name: "assistanceForm",
        href: "/form",
        activeStep: 0,
        subtabs: [],
    },
    {
        name: "businessModel",
        href: "/helpprogram",
        activeStep: 1,
        subtabs: [],
    },
    {
        name: "businessPlan",
        href: "/helpprogram",
        activeStep: 2,
        subtabs: [],
    },
    {
        name: "fundingFile",
        href: "/helpprogram",
        activeStep: 3,
        subtabs: [],
    },
    {
        name: "creation",
        href: "/helpprogram",
        activeStep: 4,
        subtabs: [],
    },
    /* {
       name: "toolbox",
       href: "/helpprogram",
       activeStep: 5,
     },*/
    {
        name: "training",
        href: "/tutorialhome",
        activeStep: 0,
        subtabs: [],
    },
    {
        name: "video",
        href: "/videoTutorials",
        activeStep: 0,
        subtabs: [],
    },
    {
        name: "PDF",
        href: "/pdfTutorials",
        activeStep: 0,
        subtabs: [],
    },

    {
        name: "networkingAndMentoring",
        href: "/networkandmonitoring",
        activeStep: 0,
        subtabs: [],
    },
];


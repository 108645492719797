// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultErrorMessage: "Une erreur est survenue, merci de réessayer plus tard",
  timeoutMessage:
    "La plateforme est momentanément indisponible. Veuillez réessayer plus tard",
  DEFAULT_ERROR: "Une erreur est survenue, merci de réessayer plus tard",
  OTP_EXPIRED: "Code Otp expiré",
  OTP_USED: "Code OTP déja utilisé",
  OTP_INCORRECT: "Code OTP incorrecte",
  TOKEN_NOT_FOUND: "Token inexistant",
  ACTION_UNAUTHORIZED: "Action non autorisée",
  PHONE_ALREADY_EXISTS: "Telephone deja existant",
  PHONE_NUMBER_NOT_VERIFIED: "Numero Telephone non verifié",
  PHONE_ALREADY_EXISTS_AND_FORM_FINISHED: "Demande déja faite par ce numero",
  INIT_PHONE_ALREADY_EXISTS:
    "Vous allez etre rediriger directement au formulaire car ce numero est deja verifié ",
  CLIENT_REQUEST_ALREADY_DONE: "Cette Demande a été déja lancée ",
  SHOULD_ACCEPT_CONDITIONS:"J'ai lu et j'accepte les conditions générales"
};

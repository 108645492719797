//@flow
import React, {useState} from "react";
import {CDBBtn, CDBCard, CDBCardBody, CDBContainer, CDBModal} from "cdbreact";
import {Row} from "react-bootstrap";

import {t} from "../../../i18n";
import ErrorSvgIcon from "../../../assets/svgs/FailureIconSvgAnimated";
import SuccessSvgIcon from "../../../assets/svgs/SuccessIconSvgAnimated";

const Modal = (props) => {
    const [state, setState] = useState({
        modal: props.show ? true : false,
    });
    const toggle = () => () => {
        setState({
            ...state,
            ["modal"]: !state["modal"],
        });
    };
    return (
        <CDBContainer>
            {props.isNotBtn ? null : (
                <CDBBtn onClick={toggle()} color="dark" circle style={props.modalStyle}>
                    {props.title}
                </CDBBtn>
            )}

            <CDBModal
                isOpen={props.isNotBtn ? true : state.modal}
                toggle={toggle()}
                centered
                fade
            >
                <CDBCard>
                    <CDBCardBody>
                        <h2>{props.newHeader ? props.newHeader : props.title}</h2>
                        {props.severity ? props.severity === "SUCCESS" ? (
                            <Row>
                                <SuccessSvgIcon/>
                            </Row>
                        ) : (
                            <Row>
                                <ErrorSvgIcon/>
                            </Row>
                        ) : null}
                        <Row>{props.children}</Row>

                        <div className="d-flex">
                            <div
                                className="d-flex justify-content-center action-btn-bottom"
                                style={{flex: " 50%"}}
                            >
                                <CDBBtn
                                    color="white"
                                    flat
                                    onClick={props.isNotBtn ? props.onCancel : toggle()}
                                    className="btn-primary"
                                >
                                    {t(`Components:Modal.close`)}
                                </CDBBtn>
                            </div>
                        </div>
                    </CDBCardBody>
                </CDBCard>
            </CDBModal>
        </CDBContainer>
    );
};
export default Modal;

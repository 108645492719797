//#region Imports
import React, {lazy, Suspense} from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {withTranslation} from "react-i18next";

import history from "../utils/history";
import MessageHandler from "../components/MessageHandler/MessageHandler";
import Loader from "../components/Loader/Loader";
import RoutingLoader from "../components/RoutingLoader/RoutingLoader";
import Header from "../components/UpperMenu/UpperMenu";
import "../App.css";
//#endregion

/**
 * @name withHeader
 * @description Function to add a Header for Pages in route  (UpperMenu)
 */
const withHeader = (children) => {
    return (
        <>
            <Header/>
            {children}
        </>
    );
};

//**************************************** */
//#region Pages Imports
const Form = lazy(() => import("../features/Form/FormScreen"));
const Home = lazy(() => import("../features/Home/HomeScreen"));
const StepsSchema = lazy(() =>
    import("../features/HelpProgram/StepsSchema/StepsSchemaScreen/StepsSchema")
);
const BusinessModel = lazy(() =>
    import("../features/HelpProgram/BusinessModel/BusinessModel")
);
const BusinessPlan = lazy(() =>
    import("../features/HelpProgram/BusinessPlan/BusinessPlan")
);
const News = lazy(() => import("../features/News/NewsScreen"));
const VideoTutorials = lazy(() =>
    import("../features/TutorialSection/TutrorialLists/VideoTurotials")
);
const PdfTutorials = lazy(() =>
    import("../features/TutorialSection/TutrorialLists/PdfTutorials")
);

const WhoAreWe = lazy(() => import("../features/WhoAreWe/WhoAreWe"));

const HelpProgram = lazy(() => import("../features/HelpProgram/HelpProgram"));

const TutorialHomePage = lazy(() =>
    import("../features/TutorialSection/TutorialHomePage")
);
const TutorialHomePageSecond = lazy(() =>
    import("../features/TutorialSection/TutorialHomePageSecond")
);
const NetworkAndMonitoring = lazy(() =>
    import("../features/NetworkAndMonitoring/NetworkAndMonitoring")
);

const GeneralConditions = lazy(() =>
    import("../features/CGU/GeneralConditions")
);
//#endregion
//#region Main Code
export default withTranslation()(() => (
    <div className="App">
        <Suspense fallback={<RoutingLoader/>}>
            <MessageHandler/>
            <Loader/>
            <div className="">
                <Router history={history}>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/home"/>
                        </Route>
                        <Route
                            exact
                            path="/form"
                            render={(props) => withHeader(<Form {...props} />)}
                        />
                        <Route
                            exact
                            path="/home"
                            render={(props) => withHeader(<Home {...props} />)}
                        />
                        <Route
                            exact
                            path="/stepsSchema"
                            render={(props) => withHeader(<StepsSchema {...props} />)}
                        />
                        <Route
                            exact
                            path="/businessmodel"
                            render={(props) => withHeader(<BusinessModel {...props} />)}
                        />
                        <Route
                            exact
                            path="/businessplan"
                            render={(props) => withHeader(<BusinessPlan {...props} />)}
                        />
                        <Route
                            exact
                            path="/news"
                            render={(props) => withHeader(<News {...props} />)}
                        />
                        <Route
                            exact
                            path="/videoTutorials"
                            render={(props) => withHeader(<VideoTutorials {...props} />)}
                        />
                        <Route
                            exact
                            path="/pdfTutorials"
                            render={(props) => withHeader(<PdfTutorials {...props} />)}
                        />
                        <Route
                            exact
                            path="/whoarewe"
                            render={(props) => withHeader(<WhoAreWe {...props} />)}
                        />
                        <Route
                            exact
                            path="/helpprogram"
                            render={(props) => withHeader(<HelpProgram {...props} />)}
                        />
                        <Route
                            exact
                            path="/tutorialhome"
                            render={(props) => withHeader(<TutorialHomePage {...props} />)}
                        />
                        <Route
                            exact
                            path="/tutorialhomesecond"
                            render={(props) =>
                                withHeader(<TutorialHomePageSecond {...props} />)
                            }
                        />
                        <Route
                            exact
                            path="/networkandmonitoring"
                            render={(props) =>
                                withHeader(<NetworkAndMonitoring {...props} />)
                            }
                        />
                        <Route
                            exact
                            path="/Cgu"
                            render={(props) =>
                                withHeader(<GeneralConditions {...props} />)
                            }
                        />
                    </Switch>
                </Router>
            </div>
        </Suspense>
    </div>
));
//#endregion

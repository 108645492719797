// eslint-disable-next-line import/no-anonymous-default-export
export default {
    title: "​Réseautage et Mentoring",
    networkAndMentorat: " LE RÉSEAU DE MENTORAT",
    networkAndMentoratDetails:
        " L’objectif de l’offre de mentorat de la FCE est d’accompagner les TPE nouvellement créées et les porteurs de projets dans la création, le démarrage et le développement des activités de leurs entreprises, en mettant à leur disposition un réseau de mentorat. Ce réseau permet notamment :",
    networkAndMentoratDetailsList1:
        " d’échanger avec des entrepreneurs seniors sur des problématiques communes et des difficultés rencontrées ;",
    networkAndMentoratDetailsList2:
        " de partager les bons plans et les astuces pour gagner en efficacité ;",
    networkAndMentoratDetailsList3: "de trouver des clients potentiels.",
    toDownload: " Pour Télécharger",
    mentoratChart: "la Charte du Mentorat, merci de",
    clickHere: "Cliquer ici",
    becomeMentoree: "DEVENEZ MENTORÉ",
    becomeMentoreeDetails:
        " Vous êtes TPE d’au moins un an et n’excédant pas 5 ans d’existence, vous voulez accélérer le développement de votre entreprise ou vous souhaitez vous ouvrir à d’autres expériences entrepreneuriales réussies, sans perdre votre autonomie en matière de prise de décision ?",
    becomeMentor: "DEVENEZ MENTOR",
    becomeMentorDetails:
        "Vous êtes un chef d’entreprise, un dirigeant de société avec une expérience probante de plus de 5 ans dans le monde de l’entrepreneuriat et vous souhaitez devenir mentor ?",
    joinClub: " REJOINDRE UN CLUB D’ENTREPRENEURS POUR DÉVELOPPER SON RÉSEAU",
    joinClubDetails:
        "Les clubs d’entrepreneurs au Maroc sont orientés vers le développement d’opportunités business et le partage de compétences. La majorité des clubs d’entrepreneurs est chapeautée par les Chambres de Commerce, d’Industrie et de Services (CCIS), les Chambres d’Artisanat ou d’Agriculture ou encore les fédérations sectorielles. \n",
    joinClubDetailsTwo: "Il est fortement recommandé d’adhérer à ces réseaux d’affaires, et de s’y investir, au vu des opportunités qu’ils offrent à toute entreprise désirant augmenter son capital relationnel et son volume d’affaires.\n",
    marketPlace: "MARKETPLACE",
    marketPlaceDetails:
        " Une marketplace est une place de marché internet dont l’objectif est de mettre en relation des vendeurs et des acheteurs. Cela se traduit par une offre très diversifiée et une réelle compétitivité en termes de prix. Les marketplaces se révèlent ainsi être de véritables accélérateurs de courant d’affaires. Néanmoins, pour que votre présence soit profitable, il faudra maîtriser parfaitement certaines règles. ",
    goldIn: "GOLD IN",
    goldInDescription:
        "Goldin.ma est une marketplace marocaine e-commerce dédiée à la bijouterie, à la joaillerie et à l’horlogerie. Elle propose une large sélection de bijoux en or jaune et en or blanc les plus classiques, mais aussi l’or rose, grande tendance ces dernières années. L’or reste le métal le plus utilisé tant pour sa qualité que son esthétique indéniable.",
    jumiaFood: "Jumia Food",
    jumiaFoodDetails:
        "Tout comme Jumia, Jumia Food spécialisé dans la restauration, offre à ses marchands une opportunité d'accroître leur business, augmenter une visibilité et livraison assurée par les livreurs de Jumia Food. ",

    theAnou: "The Anou ",
    theAnouDetails:
        "Www.theanou.com (Anou = “Puits” en berbère), est la seule plateforme e-commerce au Maroc qui appartient et qui est gérée par les artisans eux-mêmes. C’est un projet à fort impact social qui vise à mettre fin à l’exploitation abusive des intermédiaires, en permettant à l’artisan de vendre directement au client. Anou fut reconnue en 2020 par Ashoka comme l’un des projets les plus impactant de la région MENA (Middle East North Africa). C’est aujourd’hui une référence du commerce éthique à l’international.",
    myTindy: "MYTINDY",
    myTindyDetails:
        "MyTindy est la Marketplace spécialisée dans l’artisanat marocain et qui a pour vocation de permettre à l’artisan d’offrir la possibilité à n’importe quel client d’acheter en ligne des produits 100% marocains.",
    epicerieVerte: "Epicerie Verte",
    epicerieVerteDetails:
        "Épicerie Verte est la première Marketplace en ligne spécialisée dans les produits bio, naturels et du terroir au Maroc. Son but est de valoriser le Made-in-Morocco artisanal et éco-responsable aussi bien localement qu’à l’international. ",
    chicIntemporel: "Chic Intemporel ",
    chicIntemporelDetails:
        "Chic Intemporel est une marketplace de niche, à visée économique sociale et solidaire. Il s’agit d’une plateforme ciblant l’export et le marché marocain. Les marchés prioritaires sont l’Europe francophone (France, Belgique et Suisse) ainsi que le Maroc. La marketplace est bilingue (français et anglais) et propose 5 devises (Dirham, Euro, Livre, Dollars et Dirham Emirati). Cette marketplace est dédiée aux créateurs, artisans, coopératives de talent marocains, avec un parcours d’achats facilité et surtout un accompagnement permanent.",
    jumia: "Jumia",
    jumiaDetails:
        "Leader marocain de la vente en ligne depuis 8 ans, Jumia participe à la digitalisation et à la modernisation des habitudes d’achat du marocain et fournit aux commerçants une plateforme efficace et complète pour vendre sur internet. ",
    tribaliste: "TRIBALISTE",
    tribalisteDetails:
        "Tribaliste est une boutique en ligne totalement gratuite pour l’artisan, spécialisée dans l’artisanat Marocain de décoration d’intérieur. L’objectif de Tribaliste est de permettre aux artisans de vendre leurs produits en ligne facilement, en éliminant toutes les barrières du digital. Les clients de Tribaliste.com sont des passionnés de déco à la recherche de produits authentiques d’artisanat Le site intéresse aussi bien les particuliers que les professionnels (architectes d’intérieur, designers…)",
    soukTajer: "Souq Tajer ",
    soukTajerDetails:
        "1ère Marketplace BtoB au Maroc ! Destination idéale pour rencontrer les meilleurs fournisseurs et grossistes au Maroc. C’est une plateforme d’achat et vente en gros et demi-gros. ",

    glovo: "GLOVO",
    glovoDetails:
        "Glovo joue un rôle d’accélérateur d’opportunités économiques pour un grand nombre de Glovers au Maroc. La plateforme leur propose de nouvelles sources de revenus et favorise l’employabilité des jeunes en milieu urbain. Elle offre un large choix de domaines : restauration, shopping express, stores high-techs, cadeaux en tous genres… ",
    facebookBusiness: "Facebook Marketplace ",
    facebookBusinessDetails:
        "Marketplace sur Facebook permet aux utilisateurs de découvrir, d’acheter et de vendre des articles. Les utilisateurs peuvent parcourir les annonces, chercher des articles à vendre dans leur région ou trouver des produits qui peuvent être livrés.",
    instagramShopping: "Instagram Shopping",
    instagramShoppingDetails:
        "En 2018, le réseau social Instagram lance Instagram shopping. Cette nouvelle fonction permet aux entreprises qui le souhaitent (et qui respectent les règles d’éligibilité) d’ajouter des liens sur leur posts et stories vers leurs produits. Ces shoppable posts permettent donc de faciliter le processus d’achat.",
    avito: "Avito",
    avitoDetails:
        "Avito permet à tout utilisateur au Maroc de vendre ou d'acheter en toute simplicité dans sa région, sans inscription ni procédure compliquée. avito.ma met en rapport les vendeurs et les acheteurs du Maroc et offre une expérience utilisateur exceptionnelle.",

    moroccanChambersListInd:
        "List des chambres marocaines de commerce, d'industrie et de services",
    moroccanChambersListArti: "Liste des chambres marocaines de l'artisanat",
    moroccanChambersListAgr: "Liste des chambres marocaines de l'agriculture",
    sectorialFederationsList: "Liste des federations sectorielles",
    onceBusinessModelDone:
        "Pour toute assistance, prière de contacter votre conseiller régional FCE au niveau de l’antenne régionale relevant de la circonscription de votre projet (consulter notre MAPPING). Votre conseiller régional, se fera un plaisir de répondre à vos questions.",
    bpr: "Fondation Création d'Entreprises",
    city: "Villes (BRF)",
    email: "Email",
    phone: "Téléphone",
    seeOurCapsules: "Consultez nos capsules",
    videosToAdd:
        " vidéos « Site e-commerce et trafic sur internet », « Vendre sur les réseaux sociaux » et « Gamification »",
};

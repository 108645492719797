import { put, takeLatest, call, all } from "redux-saga/effects";
import Request from "../../services/apis/request";
import MessageActionTypes from "../messages/messages.types";
import RequestActionType from "./request.types";
import { actionCreator } from "../../utils/generators";
import { sagaWithLoader } from "../template/sagaWithLoader";
import { t } from "../../i18n";

function* getAllSelectionsFeature() {
  try {
    const allSelections = yield call(new Request().getSelections);
    yield put({
      type: actionCreator("res", RequestActionType.GET_ALL_SELECTIONS),
      payload: allSelections.data,
    });
  } catch (error) {
    yield all([
      put(error),
      put({
        type: actionCreator("fail", RequestActionType.GET_ALL_SELECTIONS),
      }),
    ]);
  }
}
function* getAllCitiesFeature() {
  try {
    const allCities = yield call(new Request().getCities);
    yield put({
      type: actionCreator("res", RequestActionType.GET_ALL_CITIES),
      payload: allCities.data,
    });
  } catch (error) {
    yield all([
      put(error),
      put({ type: actionCreator("fail", RequestActionType.GET_ALL_CITIES) }),
    ]);
  }
}
function* initRequestFeature(initAction) {
  try {
    const response = yield call(
      new Request().initSupRequest,
      initAction.payload
    );
    yield all([
      put({
        type: actionCreator("res", RequestActionType.INIT_REQUEST),
        payload: response.data,
      }),
    ]);
  } catch (error) {
    yield all([
      put(error),
      put({ type: actionCreator("fail", RequestActionType.INIT_REQUEST) }),
    ]);
  }
}
function* verifyIniRequestFeature(verifyAction) {
  try {
    const response = yield call(
      new Request().verifySupRequest,
      verifyAction.payload
    );
    yield all([
      put({
        type: actionCreator("res", RequestActionType.VERIFY_INIT_REQUEST),
        payload: response.data,
      }),
    ]);
  } catch (error) {
    yield all([
      put(error),
      put({
        type: actionCreator("fail", RequestActionType.VERIFY_INIT_REQUEST),
      }),
    ]);
  }
}
function* addPersonalInfromationFeature(addInfoAction) {
  try {
    const response = yield call(
      new Request().addPersonalInformation,
      addInfoAction.payload
    );
    yield all([
      put({
        type: actionCreator("res", RequestActionType.ADD_PERSONAL_INFORMATION),
        payload: response.data,
      }),
    ]);
  } catch (error) {
    yield all([
      put(error),
      put({
        type: actionCreator("fail", RequestActionType.ADD_PERSONAL_INFORMATION),
      }),
    ]);
  }
}
function* addRequestFeature(addRequestAction) {
  try {
    const response = yield call(
      new Request().addSupRequest,
      addRequestAction.payload
    );
    yield all([
      put({
        type: actionCreator("res", RequestActionType.ADD_REQUEST),
        payload: response.data,
      }),
      put({
        type: actionCreator("req", RequestActionType.SET_STEP),
        payload: 0,
      }),
      put({
        type: MessageActionTypes.SHOW_MESSAGE,
        payload: {
          severity: "SUCCESS",
          text: t(`Components:REQUEST_ADDED_SUCCESSFULLY`),
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put(error),
      put({
        type: actionCreator("fail", RequestActionType.ADD_PERSONAL_INFORMATION),
      }),
    ]);
  }
}

export function* requestSagas() {
  yield takeLatest(
    actionCreator("req", RequestActionType.GET_ALL_SELECTIONS),
    sagaWithLoader,
    getAllSelectionsFeature
  );
  yield takeLatest(
    actionCreator("req", RequestActionType.GET_ALL_CITIES),
    sagaWithLoader,
    getAllCitiesFeature
  );

  yield takeLatest(
    actionCreator("req", RequestActionType.INIT_REQUEST),
    sagaWithLoader,
    initRequestFeature
  );
  yield takeLatest(
    actionCreator("req", RequestActionType.VERIFY_INIT_REQUEST),
    sagaWithLoader,
    verifyIniRequestFeature
  );
  yield takeLatest(
    actionCreator("req", RequestActionType.ADD_PERSONAL_INFORMATION),
    sagaWithLoader,
    addPersonalInfromationFeature
  );
  yield takeLatest(
    actionCreator("req", RequestActionType.ADD_REQUEST),
    sagaWithLoader,
    addRequestFeature
  );
}

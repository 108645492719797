import MessagesActionTypes from './messages.types';

export const showMessage = (
  text,
  severity,
  validateTitle,
  hasCancelBtn,
  handleValidate,
) => ({
  type: MessagesActionTypes.SHOW_MESSAGE,
  payload: { text, severity, validateTitle, hasCancelBtn, handleValidate },
});

export const hideMessage = () => ({
  type: MessagesActionTypes.HIDE_MESSAGE,
});
